import React, { Component } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import { SUSPENSE_FALLBACK_ELEMENT } from 'src/globals'
// import Cookies from 'universal-cookie'
import Auth from 'src/Auth'

const ConfirmRegistration = React.lazy(() => import('./views/auth/ConfirmRegistration'))
const ConfirmResetPassword = React.lazy(() => import('./views/auth/ConfirmResetPassword'))

const Login = React.lazy(() => import('./views/auth/Login'))
const AutenticatedLayout = React.lazy(() => import('./containers/authenticated/AutenticatedLayout'))
const MainLayout = React.lazy(() => import('./containers/MainLayout'))
const Page401 = React.lazy(() => import('./views/pages/error-pages/Page401'))
const Page404 = React.lazy(() => import('./views/pages/error-pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/error-pages/Page500'))
class App extends Component {
    render() {
        // const cookies = new Cookies()
        return (
            <BrowserRouter>
                <React.Suspense fallback={SUSPENSE_FALLBACK_ELEMENT}>
                    <Switch>
                        <Route exact path="/error/401" name="Error 401 - Unauthorized" render={props => <Page401 {...props} />} />
                        <Route exact path="/error/404" name="Error 404 - Page Not Found" render={props => <Page404 {...props} />} />
                        <Route exact path="/error/500" name="Error 500 - Internal Server Error" render={props => <Page500 {...props} />} />
                        <Route exact path="/login" name="Cloud Console Login" render={props => <Login {...props} />} />
                        <Route exact path="/confirm-registration/:token" name="Cloud Console Confirm Registration" render={props => <ConfirmRegistration {...props} />} />
                        <Route exact path="/confirm-reset-password/:token" name="Cloud Console Reset Password" render={props => <ConfirmResetPassword {...props} />} />

                        {Auth.isAuthenticated() &&
                            <Route path="/" name="Cloud Console" render={props => <AutenticatedLayout {...props} />} />
                        }

                        {/* fallback when not logged in */}
                        <Route path="/" name="Main Layout" render={props => <MainLayout {...props} />} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        )
    }
}

export default App
