export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS'
export const GET_USER_ACCOUNTS_INDICATOR =  'GET_USER_ACCOUNTS_INDICATOR'

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ACCOUNTS_INDICATOR =  'GET_ACCOUNTS_INDICATOR'

export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const ADD_ACCOUNT_INDICATOR = 'ADD_ACCOUNT_INDICATOR'

export const GET_ACCOUNT = 'GET_ACCOUNT'
export const GET_ACCOUNT_INDICATOR = 'GET_ACCOUNT_INDICATOR'

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_INDICATOR = 'UPDATE_ACCOUNT_INDICATOR'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_INDICATOR = 'DELETE_ACCOUNT_INDICATOR'

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION'
export const ACCEPT_INVITATION_INDICATOR = 'ACCEPT_INVITATION_INDICATOR'

export const WHO_AM_I = 'WHO_AM_I'
export const WHO_AM_I_INDICATOR = 'WHO_AM_I_INDICATOR'