import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    GET_USER_ACTIVITY_LOGS,
    GET_USER_ACTIVITY_LOGS_INDICATOR,
    GET_USER_ACTIVITY_LOG,
    GET_USER_ACTIVITY_LOG_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _getUserActivityLogs = data => ({ type: GET_USER_ACTIVITY_LOGS, payload: data })
export const _getUserActivityLogsIndicator = value => ({ type: GET_USER_ACTIVITY_LOGS_INDICATOR, payload: indicatorFormatter(value) })

export const _getUserActivityLog = data => ({ type: GET_USER_ACTIVITY_LOG, payload: data })
export const _getUserActivityLogIndicator = value => ({ type: GET_USER_ACTIVITY_LOG_INDICATOR, payload: indicatorFormatter(value) })

export const getUserActivityLogs = (pagination_props) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_getUserActivityLogsIndicator(true));
        return axios.get(API_SERVER + '/api/user_activity_logs', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getUserActivityLogsIndicator(response))
            dispatch(_getUserActivityLogs(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getUserActivityLogsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const getUserActivityLog = (id) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_getUserActivityLogIndicator(true));
        return axios.get(API_SERVER + '/api/user_activity_logs/' + id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getUserActivityLogIndicator(response))
            dispatch(_getUserActivityLog(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getUserActivityLogIndicator(error))
            return { result: "failed", error }
        })
    }
}