import {
    GET_MEDIA_ASSETS,
    GET_MEDIA_ASSETS_INDICATOR,
    GET_MEDIA_ASSET,
    GET_MEDIA_ASSET_INDICATOR,
    ADD_MEDIA_ASSET,
    ADD_MEDIA_ASSET_INDICATOR,
    UPDATE_MEDIA_ASSET,
    UPDATE_MEDIA_ASSET_INDICATOR,
    DELETE_MEDIA_ASSET,
    DELETE_MEDIA_ASSET_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    mediaAssets: [],
    getMediaAssetsIndicator: defaultIndicatorValue,
    mediaAsset: {},
    getMediaAssetIndicator: defaultIndicatorValue,
    addedMediaAsset: {},
    getAddMediaAssetIndicator: defaultIndicatorValue,
    updatedMediaAsset: {},
    getUpdateMediaAssetIndicator: defaultIndicatorValue,
    deletedMediaAsset: {},
    getDeleteMediaAssetIndicator: defaultIndicatorValue,
}

const accounts_media_assets = (state = initialState, action) => {
    switch (action.type) {

        case GET_MEDIA_ASSETS:
            return { ...state, mediaAssets: action.payload }

        case GET_MEDIA_ASSETS_INDICATOR:
            return { ...state, getMediaAssetsIndicator: action.payload }

        case GET_MEDIA_ASSET:
            return { ...state, mediaAsset: action.payload }

        case GET_MEDIA_ASSET_INDICATOR:
            return { ...state, getMediaAssetIndicator: action.payload }

        case ADD_MEDIA_ASSET:
            return { ...state, addedMediaAsset: action.payload }

        case ADD_MEDIA_ASSET_INDICATOR:
            return { ...state, getAddMediaAssetIndicator: action.payload }

        case UPDATE_MEDIA_ASSET:
            return { ...state, updatedMediaAsset: action.payload }

        case UPDATE_MEDIA_ASSET_INDICATOR:
            return { ...state, getUpdateMediaAssetIndicator: action.payload }

        case DELETE_MEDIA_ASSET:
            return { ...state, deletedMediaAsset: action.payload }

        case DELETE_MEDIA_ASSET_INDICATOR:
            return { ...state, getDeleteMediaAssetIndicator: action.payload }

        default:
            return state;
    }
}

export default accounts_media_assets