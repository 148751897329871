import { combineReducers } from 'redux'

import globals from './globals.js'

import utilities from './utilities.js'
import auth from './auth.js'

import super_admin_devices from './super_admin_devices.js'
import super_admin_distributors from './super_admin_distributors.js'
import super_admin_firmwares from './super_admin_firmwares.js'
import super_admin_phone_app from './super_admin_phone_app.js'
import super_admin_user_activity_logs from './super_admin_user_activity_logs.js'

// import distributors from './distributors.js'
import accounts from './accounts.js'
import accounts_users from './accounts_users.js'

import accounts_media_assets from './accounts_media_assets.js'
import accounts_devices from './accounts_devices.js'
import accounts_groups from './accounts_groups.js'
import accounts_playlists from './accounts_playlists.js'
import accounts_layouts from './accounts_layouts.js'

import account_settings from './account_settings.js'

const rootReducer = combineReducers({
    globals,
    utilities,
    auth,

    super_admin_devices,
    super_admin_distributors,
    super_admin_firmwares,
    super_admin_phone_app,
    super_admin_user_activity_logs,
    
    // distributors,
    accounts,
    accounts_users,
    accounts_devices,
    accounts_media_assets,
    accounts_groups,
    accounts_playlists,
    accounts_layouts,

    account_settings
});

export default rootReducer