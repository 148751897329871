export const GET_PHONE_APPS = 'GET_PHONE_APPS'
export const GET_PHONE_APPS_INDICATOR = 'GET_PHONE_APPS_INDICATOR'

export const ADD_PHONE_APP = 'ADD_PHONE_APP'
export const ADD_PHONE_APP_INDICATOR = 'ADD_PHONE_APP_INDICATOR'

export const GET_PHONE_APP = 'GET_PHONE_APP'
export const GET_PHONE_APP_INDICATOR = 'GET_PHONE_APP_INDICATOR'

export const DELETE_PHONE_APP = 'DELETE_PHONE_APP'
export const DELETE_PHONE_APP_INDICATOR = 'DELETE_PHONE_APP_INDICATOR'

export const VIEW_PHONE_APP = 'VIEW_PHONE_APP'
export const VIEW_PHONE_APP_INDICATOR = 'VIEW_PHONE_APP_INDICATOR'
