// get all devices
export const GET_PLAYLISTS = 'GET_PLAYLISTS'
export const GET_PLAYLISTS_INDICATOR = 'GET_PLAYLISTS_INDICATOR'

export const GET_PLAYLIST = 'GET_PLAYLIST'
export const GET_PLAYLIST_INDICATOR = 'GET_PLAYLIST_INDICATOR'

export const ADD_PLAYLIST = 'ADD_PLAYLIST'
export const ADD_PLAYLIST_INDICATOR = 'ADD_PLAYLIST_INDICATOR'

export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST'
export const UPDATE_PLAYLIST_INDICATOR = 'UPDATE_PLAYLIST_INDICATOR'

export const DELETE_PLAYLIST = 'DELETE_PLAYLIST'
export const DELETE_PLAYLIST_INDICATOR = 'DELETE_PLAYLIST_INDICATOR'
