import {
    GET_DEVICES,
    GET_DEVICES_INDICATOR,
    GET_DEVICE,
    GET_DEVICE_INDICATOR,
    ADD_DEVICE,
    ADD_DEVICE_INDICATOR,
    UPDATE_DEVICE,
    UPDATE_DEVICE_INDICATOR,
    DELETE_DEVICE,
    DELETE_DEVICE_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    devices: [],
    getDevicesIndicator: defaultIndicatorValue,
    device: {},
    getDeviceIndicator: defaultIndicatorValue,
    addedDevice: {},
    getAddDeviceIndicator: defaultIndicatorValue,
    updatedDevice: {},
    getUpdateDeviceIndicator: defaultIndicatorValue,
    deletedDevice: {},
    getDeleteDeviceIndicator: defaultIndicatorValue,
}

const accounts_devices = (state = initialState, action) => {
    switch (action.type) {

        case GET_DEVICES:
            return { ...state, devices: action.payload }

        case GET_DEVICES_INDICATOR:
            return { ...state, getDevicesIndicator: action.payload }

        case GET_DEVICE:
            return { ...state, device: action.payload }

        case GET_DEVICE_INDICATOR:
            return { ...state, getDeviceIndicator: action.payload }

        case ADD_DEVICE:
            return { ...state, addedDevice: action.payload }

        case ADD_DEVICE_INDICATOR:
            return { ...state, getAddDeviceIndicator: action.payload }

        case UPDATE_DEVICE:
            return { ...state, updatedDevice: action.payload }

        case UPDATE_DEVICE_INDICATOR:
            return { ...state, getUpdateDeviceIndicator: action.payload }

        case DELETE_DEVICE:
            return { ...state, deletedDevice: action.payload }

        case DELETE_DEVICE_INDICATOR:
            return { ...state, getDeleteDeviceIndicator: action.payload }

        default:
            return state;
    }
}

export default accounts_devices