import {
    GET_LAYOUTS,
    LAYOUTS_ARE_LOADED,
    GET_LAYOUTS_INDICATOR,
    GET_LAYOUT,
    LAYOUT_IS_LOADED,
    GET_LAYOUT_INDICATOR,

    ADD_LAYOUT,
    ADD_LAYOUT_INDICATOR,
    UPDATE_LAYOUT,
    UPDATE_LAYOUT_INDICATOR,
    DELETE_LAYOUT,
    DELETE_LAYOUT_INDICATOR,

    GET_DEVICE_LIST,
    GET_DEVICE_LIST_INDICATOR,
    ADDED_DEVICE_LIST,
    ADDED_DEVICE_LIST_INDICATOR,
    DELETE_ADDED_DEVICE_LIST,
    DELETE_ADDED_DEVICE_LIST_INDICATOR,

    GET_DEVICE_GROUPS_LIST,
    GET_DEVICE_GROUPS_LIST_INDICATOR,
    ADDED_DEVICE_GROUPS_LIST,
    ADDED_DEVICE_GROUPS_LIST_INDICATOR,
    DELETE_ADDED_DEVICE_GROUPS_LIST,
    DELETE_ADDED_DEVICE_GROUPS_LIST_INDICATOR,

    GET_PLAYLISTS,
    GET_PLAYLISTS_INDICATOR,

    UPDATE_LAYOUT_CANVAS,
    UPDATE_LAYOUT_CANVAS_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    layouts: [],
    layoutsAreLoaded: false, // must be replaced with getLayoutsIndicator.false
    getLayoutsIndicator: defaultIndicatorValue,

    layout: {},
    layoutIsLoaded: false,
    getLayoutIndicator: defaultIndicatorValue,

    addedLayout: {},
    addLayoutIndicator: defaultIndicatorValue,

    updatedLayout: {},
    updateLayoutIndicator: defaultIndicatorValue,

    deletedLayout: {},
    deleteLayoutIndicator: defaultIndicatorValue,

    deviceList: [],
    getDeviceListIndicator: defaultIndicatorValue,

    deviceGroupsList: [],
    getDeviceGroupsListIndicator: defaultIndicatorValue,

    addedDeviceListIndicator: false,
    addedDeviceGroupsListIndicator: false,

    deletedAddedDevice: {},
    getDeleteAddedDeviceIndicator: defaultIndicatorValue,

    deletedAddedDeviceGroups: {},
    getDeleteAddedDeviceGroupsIndicator: defaultIndicatorValue,

    playlists: [],
    getPlaylistsIndicator: defaultIndicatorValue,

    updatedLayoutCanvas: {},
    getUpdateLayoutCanvasIndicator: defaultIndicatorValue,

}

const accounts_layouts = (state = initialState, action) => {
    switch (action.type) {

        case GET_DEVICE_LIST:
            return { ...state, deviceList: action.payload }

        case GET_DEVICE_LIST_INDICATOR:
            return { ...state, getDeviceListIndicator: action.payload }

        case ADDED_DEVICE_LIST:
            return { ...state, addedDeviceListIndicator: false, deviceList: [...state.deviceList, action.payload] }

        case ADDED_DEVICE_LIST_INDICATOR:
            return { ...state, addedDeviceListIndicator: action.payload }

        case DELETE_ADDED_DEVICE_LIST:
            return { ...state, deletedAddedDevice: action.payload }

        case DELETE_ADDED_DEVICE_LIST_INDICATOR:
            return { ...state, getDeleteAddedDeviceIndicator: action.payload }

        case GET_DEVICE_GROUPS_LIST:
            return { ...state, deviceGroupsList: action.payload }

        case GET_DEVICE_GROUPS_LIST_INDICATOR:
            return { ...state, getDeviceGroupsListIndicator: action.payload }

        case ADDED_DEVICE_GROUPS_LIST:
            return { ...state, addedDeviceGroupsListIndicator: false, deviceGroupsList: [...state.deviceGroupsList, action.payload] }

        case ADDED_DEVICE_GROUPS_LIST_INDICATOR:
            return { ...state, addedDeviceGroupsListIndicator: action.payload }

        case DELETE_ADDED_DEVICE_GROUPS_LIST:
            return { ...state, deletedAddedDeviceGroups: action.payload }

        case DELETE_ADDED_DEVICE_GROUPS_LIST_INDICATOR:
            return { ...state, getDeleteAddedDeviceGroupsIndicator: action.payload }

        case GET_LAYOUTS:
            return { ...state, layouts: action.payload }

        case LAYOUTS_ARE_LOADED:
            return { ...state, layoutsAreLoaded: action.payload }

        case GET_LAYOUTS_INDICATOR:
            return { ...state, getLayoutsIndicator: action.payload }

        case GET_LAYOUT:
            return { ...state, layout: action.payload }

        case LAYOUT_IS_LOADED:
            return { ...state, layoutIsLoaded: action.payload }

        case GET_LAYOUT_INDICATOR:
            return { ...state, getLayoutIndicator: action.payload }

        case ADD_LAYOUT:
            return { ...state, added_layout: action.payload }

        case ADD_LAYOUT_INDICATOR:
            return { ...state, addLayoutIndicator: action.payload }

        case UPDATE_LAYOUT:
            return { ...state, updated_layout: action.payload }

        case UPDATE_LAYOUT_INDICATOR:
            return { ...state, updateLayoutIndicator: action.payload }

        case DELETE_LAYOUT:
            return { ...state, deleted_layout: action.payload }

        case DELETE_LAYOUT_INDICATOR:
            return { ...state, deleteLayoutIndicator: action.payload }

        case GET_PLAYLISTS:
            return { ...state, playlists: action.payload }

        case GET_PLAYLISTS_INDICATOR:
            return { ...state, getPlaylistsIndicator: action.payload }

        case UPDATE_LAYOUT_CANVAS:
            return { ...state, updatedLayoutCanvas: action.payload }

        case UPDATE_LAYOUT_CANVAS_INDICATOR:
            return { ...state, getUpdateLayoutCanvasIndicator: action.payload }

        default:
            return state;
    }
}

export default accounts_layouts