import {
    GET_PHONE_APPS,
    GET_PHONE_APPS_INDICATOR,
    ADD_PHONE_APP,
    ADD_PHONE_APP_INDICATOR,
    GET_PHONE_APP,
    GET_PHONE_APP_INDICATOR,
    DELETE_PHONE_APP,
    DELETE_PHONE_APP_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    phoneApps: [],
    getPhoneAppsIndicator: defaultIndicatorValue,
    addPhoneApp: {},
    getAddPhoneAppIndicator: defaultIndicatorValue,
    phoneApp: {},
    getPhoneAppIndicator: defaultIndicatorValue,
    deletedPhoneApp: {},
    deletePhoneAppIndicator: defaultIndicatorValue,
}

const super_admin_phone_app = (state = initialState, action) => {
    switch (action.type) {

        case GET_PHONE_APPS:
            return { ...state, phoneApps: action.payload }

        case GET_PHONE_APPS_INDICATOR:
            return { ...state, getPhoneAppsIndicator: action.payload }

        case ADD_PHONE_APP:
            return { ...state, addPhoneApp: action.payload }

        case ADD_PHONE_APP_INDICATOR:
            return { ...state, getAddPhoneAppIndicator: action.payload }

        case GET_PHONE_APP:
            return { ...state, phoneApp: action.payload }

        case GET_PHONE_APP_INDICATOR:
            return { ...state, getPhoneAppIndicator: action.payload }

        case DELETE_PHONE_APP:
            return { ...state, deletedPhoneApp: action.payload }

        case DELETE_PHONE_APP_INDICATOR:
            return { ...state, deletePhoneAppIndicator: action.payload }

        default:
            return state;
    }
}

export default super_admin_phone_app