import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    GET_DISTRIBUTORS,
    GET_DISTRIBUTORS_INDICATOR,
    ADD_DISTRIBUTOR,
    ADD_DISTRIBUTOR_INDICATOR,
    GET_DISTRIBUTOR,
    GET_DISTRIBUTOR_INDICATOR,
    UPDATE_DISTRIBUTOR,
    UPDATE_DISTRIBUTOR_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _getDistributors = data => ({ type: GET_DISTRIBUTORS, payload: data })
export const _getDistributorsIndicator = value => ({ type: GET_DISTRIBUTORS_INDICATOR, payload: indicatorFormatter(value) })

export const _addDistributor = data => ({ type: ADD_DISTRIBUTOR, payload: data })
export const _addDistributorIndicator = value => ({ type: ADD_DISTRIBUTOR_INDICATOR, payload: indicatorFormatter(value) })

export const _getDistributor = data => ({ type: GET_DISTRIBUTOR, payload: data })
export const _getDistributorIndicator = value => ({ type: GET_DISTRIBUTOR_INDICATOR, payload: indicatorFormatter(value) })

export const _updateDistributor = data => ({ type: UPDATE_DISTRIBUTOR, payload: data })
export const _updateDistributorIndicator = value => ({ type: UPDATE_DISTRIBUTOR_INDICATOR, payload: indicatorFormatter(value) })

export const getDistributors = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getDistributorsIndicator(true));
        return axios.get(API_SERVER + '/api/users', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getDistributorsIndicator(response))
            dispatch(_getDistributors(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getDistributorsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const addDistributor = (payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_addDistributorIndicator(true));
        return axios.post(API_SERVER + '/api/users', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_addDistributorIndicator(response))
            dispatch(_addDistributor(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_addDistributorIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const getDistributor = (user_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getDistributorIndicator(true));
        return axios.get(API_SERVER + '/api/users/' + user_id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getDistributorIndicator(response))
            dispatch(_getDistributor(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getDistributorIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const updateDistributor = (user_id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_updateDistributorIndicator(true));
        return axios.put(API_SERVER + '/api/users/' + user_id, payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_updateDistributorIndicator(response))
            dispatch(_updateDistributor(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_updateDistributorIndicator(error))
            return { result: "failed", error }
        })
    }
}