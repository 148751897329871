import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'
import { v4 as uuidv4 } from 'uuid'

import {
    SUPER_ADMIN_GET_DEVICES,
    SUPER_ADMIN_GET_DEVICES_INDICATOR,
    SUPER_ADMIN_GET_DEVICE,
    SUPER_ADMIN_GET_DEVICE_INDICATOR,
    SUPER_ADMIN_UPDATED_DEVICE,
    SUPER_ADMIN_UPDATE_DEVICE_INDICATOR,
    SUPER_ADMIN_GET_DEVICE_LOGS,
    SUPER_ADMIN_GET_DEVICE_LOGS_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _superAdminGetDevices = data => ({ type: SUPER_ADMIN_GET_DEVICES, payload: data })
export const _superAdminGetDevicesIndicator = value => ({ type: SUPER_ADMIN_GET_DEVICES_INDICATOR, payload: indicatorFormatter(value) })

export const _superAdminGetDevice = data => ({ type: SUPER_ADMIN_GET_DEVICE, payload: data })
export const _superAdminGetDeviceIndicator = value => ({ type: SUPER_ADMIN_GET_DEVICE_INDICATOR, payload: indicatorFormatter(value) })

export const _superAdminUpdatedDevice = data => ({ type: SUPER_ADMIN_UPDATED_DEVICE, payload: data })
export const _superAdminUpdateDeviceIndicator = value => ({ type: SUPER_ADMIN_UPDATE_DEVICE_INDICATOR, payload: indicatorFormatter(value) })

export const _superAdminGetDeviceLogs = data => ({ type: SUPER_ADMIN_GET_DEVICE_LOGS, payload: data })
export const _superAdminGetDeviceLogsIndicator = value => ({ type: SUPER_ADMIN_GET_DEVICE_LOGS_INDICATOR, payload: indicatorFormatter(value) })

export const superAdminGetDevices = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_superAdminGetDevicesIndicator(true));
        return axios.get(API_SERVER + '/api/devices', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_superAdminGetDevicesIndicator(response))
            dispatch(_superAdminGetDevices(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_superAdminGetDevicesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const superAdminGetDevice = (id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_superAdminGetDeviceIndicator(true));
        return axios.get(API_SERVER + '/api/devices/' + id + '/edit', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_superAdminGetDeviceIndicator(response))
            dispatch(_superAdminGetDevice(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_superAdminGetDeviceIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const superAdminUpdateDevice = (id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_superAdminUpdateDeviceIndicator(true))
        return axios.post(API_SERVER + '/api/devices/' + id + '?_method=PATCH', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log(response)
            dispatch(_superAdminUpdateDeviceIndicator(response))
            dispatch(_superAdminUpdatedDevice(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            dispatch(_superAdminUpdateDeviceIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const superAdminRebootDevice = (id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        return axios.post(API_SERVER + '/api/devices/' + id + '/reboot', [], {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log(response)
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            return { result: "failed", error }
        })
    }
}

export const superAdminGetDeviceLogs = (pagination_props) => {
    // search by device_id
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_superAdminGetDeviceLogsIndicator(true));
        return axios.get(API_SERVER + '/api/v2/device_status_logs', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // TODO: data returning same id
            const data = response.data.collection.map((log) => {
                log.id = uuidv4()
                return log
            })
            dispatch(_superAdminGetDeviceLogsIndicator(response))
            dispatch(_superAdminGetDeviceLogs({ collection: data, meta: response.data.meta }))
            return { result: "success", data: { collection: data, meta: response.data.meta } }
        }).catch((error) => {
            dispatch(_superAdminGetDeviceLogsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const localUpdateDevicesData = (data) => {
    return (dispatch) => {
        dispatch({ type: SUPER_ADMIN_GET_DEVICES, payload: data })
    }
}