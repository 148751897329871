export const GET_FIRMWARES = 'GET_FIRMWARES'
export const GET_FIRMWARES_INDICATOR = 'GET_FIRMWARES_INDICATOR'

export const GET_FIRMWARE = 'GET_FIRMWARE'
export const GET_FIRMWARE_INDICATOR = 'GET_FIRMWARE_INDICATOR'

export const ADD_FIRMWARE = 'ADD_FIRMWARE'
export const ADD_FIRMWARE_INDICATOR = 'ADD_FIRMWARE_INDICATOR'

export const UPDATE_FIRMWARE = 'UPDATE_FIRMWARE'
export const UPDATE_FIRMWARE_INDICATOR = 'UPDATE_FIRMWARE_INDICATOR'

export const DELETE_FIRMWARE = 'DELETE_FIRMWARE'
export const DELETE_FIRMWARE_INDICATOR = 'DELETE_FIRMWARE_INDICATOR'

export const TOGGLE_DISABLE_FIRMWARE = 'TOGGLE_DISABLE_FIRMWARE'
export const TOGGLE_DISABLE_FIRMWARE_INDICATOR = 'TOGGLE_DISABLE_FIRMWARE_INDICATOR'


