export const GET_ACCOUNT_USERS = 'GET_ACCOUNT_USERS'
export const GET_ACCOUNT_USERS_INDICATOR =  'GET_ACCOUNT_USERS_INDICATOR'

export const ADD_ACCOUNT_USER = 'ADD_ACCOUNT_USER'
export const ADD_ACCOUNT_USER_INDICATOR = 'ADD_ACCOUNT_USER_INDICATOR'

export const GET_ACCOUNT_USER = 'GET_ACCOUNT_USER'
export const GET_ACCOUNT_USER_INDICATOR = 'GET_ACCOUNT_USER_INDICATOR'

export const UPDATE_ACCOUNT_USER = 'UPDATE_ACCOUNT_USER'
export const UPDATE_ACCOUNT_USER_INDICATOR = 'UPDATE_ACCOUNT_USER_INDICATOR'

export const DELETE_ACCOUNT_USER = 'DELETE_ACCOUNT_USER'
export const DELETE_ACCOUNT_USER_INDICATOR = 'DELETE_ACCOUNT_USER_INDICATOR'