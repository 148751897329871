import Cookies from 'universal-cookie'
import { API_SERVER } from 'src/globals'
import axios from 'axios'

// import { 
//     clearUserCookie,
// } from "js/global_methods/index"

export default class Auth {
    static login(callback, email, password) {
        axios.post(API_SERVER + '/api/login', {
            email: email,
            password: password
        }, {
            "headers": {
                "Accept": "application/json",
            }
        }).then((response) => {
            if (typeof callback === 'function') {
                callback(true, response)
            }
        }).catch((error) => {
            if (typeof callback === 'function') {
                callback(false, error.response)
            }
        })
    }

    static confirmRegistration(callback, payload) {
        axios.post(API_SERVER + '/api/registration', payload, {
            "headers": {
                "Accept": "application/json",
            }
        }).then((response) => {
            if (typeof callback === 'function') {
                callback(true, response)
            }
        }).catch((error) => {
            if (typeof callback === 'function') {
                callback(false, error.response)
            }
        })
    }

    static callResetPasswordByEmail(callback, payload) {
        axios.post(API_SERVER + '/api/password/forgot', payload, {
            "headers": {
                "Accept": "application/json",
            }
        }).then((response) => {
            if (typeof callback === 'function') {
                callback(true, response)
            }
        }).catch((error) => {
            if (typeof callback === 'function') {
                callback(false, error.response)
            }
        })
    }

    static confirmResetPassword(callback, payload) {
        axios.post(API_SERVER + '/api/password/reset', payload, {
            "headers": {
                "Accept": "application/json",
            }
        }).then((response) => {
            if (typeof callback === 'function') {
                callback(true, response)
            }
        }).catch((error) => {
            if (typeof callback === 'function') {
                callback(false, error.response)
            }
        })
    }

    static logout(token, callback) {
        axios.post(API_SERVER + '/api/logout', {}, {
            "headers": {
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
        }).then((response) => {
            if (typeof callback === 'function') {
                callback(true, response)
            }
        }).catch((error) => {
            if (typeof callback === 'function') {
                callback(false, error.response)
            }
        })
    }

    static isAuthenticated = () => {
        const cookies = new Cookies()
        if (cookies.get('user_token') && cookies.get('user_role')) {
            return true
        } else {
            return false
        }
    }
}
