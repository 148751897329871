import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
    cibAdobeAcrobatReader,
    cilAddressBook,
    cibSkype,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
} from '@coreui/icons'
import {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
} from '@coreui/icons'
import {
    cilAccountLogout,
    cilActionUndo,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilApps,
    cilArrowRight,
    cilArrowLeft,
    cilListHighPriority,
    cilMediaStepForward,
    cilMobile,
    cilMemory,
    cilArrowTop,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilBuilding,
    cilCaretTop,
    cilCopy,
    cilCalculator,
    cilCalendar,
    cilClipboard,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilColumns,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDevices,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilExcerpt,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFeaturedPlaylist,
    cilFullscreen,
    cilFullscreenExit,
    cilGraph,
    cilHistory,
    cilHome,
    cilImage,
    cilInbox,
    cilInfo,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilLockUnlocked,
    cilMagnifyingGlass,
    cilMap,
    cilMediaPlay,
    cilMoon,
    cilMovie,
    cilNotes,
    cilOptions,
    cilObjectGroup,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPlus,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilReload,
    cilScrubber,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTextSquare,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilWarning,
    cilExternalLink,
    cilVerticalAlignTop,
    cilVerticalAlignCenter,
    cilVerticalAlignBottom,
    cilFlipToFront,
    cilFlipToBack
} from '@coreui/icons'

export const icons = Object.assign({}, {
    sygnet,
    logo,
    logoNegative,
}, {
    cilAccountLogout,
    cilActionUndo,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilApps,
    cilArrowRight,
    cilArrowLeft,
    cilListHighPriority,
    cilMediaStepForward,
    cilMobile,
    cilMemory,
    cilArrowTop,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilBuilding,
    cilCalculator,
    cilCalendar,
    cilClipboard,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilColumns,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDevices,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilExcerpt,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFeaturedPlaylist,
    cilFullscreen,
    cilFullscreenExit,
    cilGraph,
    cilHistory,
    cilHome,
    cilImage,
    cilInbox,
    cilInfo,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilLockUnlocked,
    cilMagnifyingGlass,
    cilMap,
    cilMediaPlay,
    cilMoon,
    cilMovie,
    cilNotes,
    cilOptions,
    cilObjectGroup,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPlus,
    cilPrint,
    cilPuzzle,
    cilReload,
    cilSave,
    cilScrubber,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilCaretTop,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTextSquare,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilWarning,
    cilCopy,
    cilExternalLink,
    cilVerticalAlignTop,
    cilVerticalAlignCenter,
    cilVerticalAlignBottom,
    cilFlipToFront,
    cilFlipToBack
}, {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
}, {
    cibAdobeAcrobatReader,
    cilAddressBook,
    cibSkype,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
})
