import {
    SUPER_ADMIN_GET_DEVICES,
    SUPER_ADMIN_GET_DEVICES_INDICATOR,
    SUPER_ADMIN_GET_DEVICE,
    SUPER_ADMIN_GET_DEVICE_INDICATOR,
    SUPER_ADMIN_UPDATED_DEVICE,
    SUPER_ADMIN_UPDATE_DEVICE_INDICATOR,
    SUPER_ADMIN_GET_DEVICE_LOGS,
    SUPER_ADMIN_GET_DEVICE_LOGS_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    allDevices: [],
    getAllDevicesIndicator: defaultIndicatorValue,
    device: {},
    getDeviceIndicator: defaultIndicatorValue,
    updatedDevice: {},
    updateDeviceIndicator: defaultIndicatorValue,
    deviceLogs: [],
    getDeviceLogsIndicator: defaultIndicatorValue,
}

const super_admin_devices = (state = initialState, action) => {
    switch (action.type) {

        case SUPER_ADMIN_GET_DEVICES:
            return { ...state, allDevices: action.payload }

        case SUPER_ADMIN_GET_DEVICES_INDICATOR:
            return { ...state, getAllDevicesIndicator: action.payload }

        case SUPER_ADMIN_GET_DEVICE:
            return { ...state, device: action.payload }

        case SUPER_ADMIN_GET_DEVICE_INDICATOR:
            return { ...state, getDeviceIndicator: action.payload }

        case SUPER_ADMIN_UPDATED_DEVICE:
            return { ...state, device: action.payload }

        case SUPER_ADMIN_UPDATE_DEVICE_INDICATOR:
            return { ...state, updateDeviceIndicator: action.payload }

        case SUPER_ADMIN_GET_DEVICE_LOGS:
            return { ...state, deviceLogs: action.payload }

        case SUPER_ADMIN_GET_DEVICE_LOGS_INDICATOR:
            return { ...state, getDeviceLogsIndicator: action.payload }

        default:
            return state;
    }
}

export default super_admin_devices