import {
    GET_FIRMWARES,
    GET_FIRMWARES_INDICATOR,
    GET_FIRMWARE,
    GET_FIRMWARE_INDICATOR,
    ADD_FIRMWARE,
    ADD_FIRMWARE_INDICATOR,
    UPDATE_FIRMWARE,
    UPDATE_FIRMWARE_INDICATOR,
    DELETE_FIRMWARE,
    DELETE_FIRMWARE_INDICATOR,
    TOGGLE_DISABLE_FIRMWARE,
    TOGGLE_DISABLE_FIRMWARE_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    firmwares: [],
    getFirmwaresIndicator: defaultIndicatorValue,
    firmware: {},
    getFirmwareIndicator: defaultIndicatorValue,
    addedFirmware: {},
    getAddFirmwareIndicator: defaultIndicatorValue,
    updatedFirmware: {},
    updateFirmwareIndicator: defaultIndicatorValue,
    deletedFirmware: {},
    deleteFirmwareIndicator: defaultIndicatorValue,
    toggleDisabledFirmware: {},
    toggleDisabledFirmwareIndicator: defaultIndicatorValue,
}

const super_admin_firmwares = (state = initialState, action) => {
    switch (action.type) {

        case GET_FIRMWARES:
            return { ...state, firmwares: action.payload }

        case GET_FIRMWARES_INDICATOR:
            return { ...state, getFirmwaresIndicator: action.payload }

        case GET_FIRMWARE:
            return { ...state, firmware: action.payload }

        case GET_FIRMWARE_INDICATOR:
            return { ...state, getFirmwareIndicator: action.payload }

        case ADD_FIRMWARE:
            return { ...state, addedFirmware: action.payload }

        case ADD_FIRMWARE_INDICATOR:
            return { ...state, getAddFirmwareIndicator: action.payload }

        case UPDATE_FIRMWARE:
            return { ...state, updatedFirmware: action.payload }

        case UPDATE_FIRMWARE_INDICATOR:
            return { ...state, updateFirmwareIndicator: action.payload }

        case DELETE_FIRMWARE:
            return { ...state, deletedFirmware: action.payload }

        case DELETE_FIRMWARE_INDICATOR:
            return { ...state, deleteFirmwareIndicator: action.payload }

        case TOGGLE_DISABLE_FIRMWARE:
            return { ...state, toggleDisabledFirmware: action.payload }

        case TOGGLE_DISABLE_FIRMWARE_INDICATOR:
            return { ...state, toggleDisabledFirmwareIndicator: action.payload }

        default:
            return state;
    }
}

export default super_admin_firmwares