export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS'
export const GET_DISTRIBUTORS_INDICATOR =  'GET_DISTRIBUTORS_INDICATOR'

export const ADD_DISTRIBUTOR = 'ADD_DISTRIBUTOR'
export const ADD_DISTRIBUTOR_INDICATOR = 'ADD_DISTRIBUTOR_INDICATOR'

export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR'
export const GET_DISTRIBUTOR_INDICATOR = 'GET_DISTRIBUTOR_INDICATOR'

export const UPDATE_DISTRIBUTOR = 'UPDATE_DISTRIBUTOR'
export const UPDATE_DISTRIBUTOR_INDICATOR = 'UPDATE_DISTRIBUTOR_INDICATOR'

export const DELETE_DISTRIBUTOR = 'DELETE_DISTRIBUTOR'
export const DELETE_DISTRIBUTOR_INDICATOR = 'DELETE_DISTRIBUTOR_INDICATOR'