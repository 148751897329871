import axios from "axios";

import { API_SERVER } from "src/globals";
import Cookies from "universal-cookie";

import {
  GET_PLAYLISTS,
  GET_PLAYLISTS_INDICATOR,
  GET_PLAYLIST,
  GET_PLAYLIST_INDICATOR,
  ADD_PLAYLIST,
  ADD_PLAYLIST_INDICATOR,
  UPDATE_PLAYLIST,
  UPDATE_PLAYLIST_INDICATOR,
  DELETE_PLAYLIST,
  DELETE_PLAYLIST_INDICATOR,
} from "../constants/action-types";

import { indicatorFormatter } from "../global_methods";

export const _getPlaylists = (data) => ({ type: GET_PLAYLISTS, payload: data });
export const _getPlaylistsIndicator = (value) => ({
  type: GET_PLAYLISTS_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _getPlaylist = (data) => ({ type: GET_PLAYLIST, payload: data });
export const _getPlaylistIndicator = (value) => ({
  type: GET_PLAYLIST_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _addPlaylist = (data) => ({ type: ADD_PLAYLIST, payload: data });
export const _addPlaylistIndicator = (value) => ({
  type: ADD_PLAYLIST_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _updatePlaylist = (data) => ({
  type: UPDATE_PLAYLIST,
  payload: data,
});
export const _updatePlaylistIndicator = (value) => ({
  type: UPDATE_PLAYLIST_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _deletePlaylist = (data) => ({
  type: DELETE_PLAYLIST,
  payload: data,
});
export const _deletePlaylistIndicator = (value) => ({
  type: DELETE_PLAYLIST_INDICATOR,
  payload: indicatorFormatter(value),
});

export const getPlaylists = (pagination_props) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_getPlaylistsIndicator(true));
    return axios
      .get(
        API_SERVER + "/api/v2/accounts/" + selected_account.id + "/playlists",
        {
          params: pagination_props,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_getPlaylistsIndicator(response));
        dispatch(_getPlaylists(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_getPlaylistsIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const addPlaylist = (payload) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_addPlaylistIndicator(true));
    return axios
      .post(
        API_SERVER + "/api/accounts/" + selected_account.id + "/playlists",
        payload,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_addPlaylistIndicator(response));
        dispatch(_getPlaylist(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_addPlaylistIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const getPlaylist = (id) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_getPlaylistIndicator(true));
    return axios
      .get(
        API_SERVER +
          "/api/v2/accounts/" +
          selected_account.id +
          "/playlists/" +
          id,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_getPlaylistIndicator(response));
        dispatch(_getPlaylist(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_getPlaylistIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const updatePlaylist = (id, payload) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_updatePlaylistIndicator(true));
    return axios
      .post(
        API_SERVER +
          "/api/accounts/" +
          selected_account.id +
          "/playlists/" +
          id +
          "?_method=PATCH",
        payload,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(_updatePlaylistIndicator(response));
        dispatch(_updatePlaylist(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        console.log(error);
        dispatch(_updatePlaylistIndicator(error));
        return { result: "failed", error };
      });
  };
};
