import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    GET_USER_ACCOUNTS,
    GET_USER_ACCOUNTS_INDICATOR,
    GET_ACCOUNTS,
    GET_ACCOUNTS_INDICATOR,
    ADD_ACCOUNT,
    ADD_ACCOUNT_INDICATOR,
    GET_ACCOUNT,
    GET_ACCOUNT_INDICATOR,
    UPDATE_ACCOUNT,
    UPDATE_ACCOUNT_INDICATOR,
    WHO_AM_I,
    WHO_AM_I_INDICATOR,
    // DELETE_ACCOUNT,
    // DELETE_ACCOUNT_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _getUserAccounts = data => ({ type: GET_USER_ACCOUNTS, payload: data })
export const _getUserAccountsIndicator = value => ({ type: GET_USER_ACCOUNTS_INDICATOR, payload: indicatorFormatter(value) })

export const _getAccounts = data => ({ type: GET_ACCOUNTS, payload: data })
export const _getAccountsIndicator = value => ({ type: GET_ACCOUNTS_INDICATOR, payload: indicatorFormatter(value) })

export const _addAccount = data => ({ type: ADD_ACCOUNT, payload: data })
export const _addAccountIndicator = value => ({ type: ADD_ACCOUNT_INDICATOR, payload: indicatorFormatter(value) })

export const _getAccount = data => ({ type: GET_ACCOUNT, payload: data })
export const _getAccountIndicator = value => ({ type: GET_ACCOUNT_INDICATOR, payload: indicatorFormatter(value) })

export const _updateAccount = data => ({ type: UPDATE_ACCOUNT, payload: data })
export const _updateAccountIndicator = value => ({ type: UPDATE_ACCOUNT_INDICATOR, payload: indicatorFormatter(value) })

// export const _deleteAccount = data => ({ type: DELETE_ACCOUNT, payload: data })
// export const _deleteAccountIndicator = value => ({ type: DELETE_ACCOUNT_INDICATOR, payload: indicatorFormatter(value) })

export const _whoAmI = data => ({ type: WHO_AM_I, payload: data })
export const _whoAmIIndicator = value => ({ type: WHO_AM_I_INDICATOR, payload: indicatorFormatter(value) })

export const setSelectedAccount = (account_obj) => {
    const cookies = new Cookies()
    cookies.set(
        'selected_account',
        JSON.stringify(account_obj),
        {
            path: '/',
            maxAge: 86400
        }
    )

    setTimeout(() => {
        window.location.href = "/"
    }, 400)
}

// mainly used for getting user shared accounts
export const getUserAccounts = () => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getUserAccountsIndicator(true))
        return axios.get(API_SERVER + '/api/accounts', {
            params: {
                page: 1,
                page_size: 100,
                sort_field: "id",
                sort_direction: "asc",
                term: ""
            },
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getUserAccountsIndicator(response))
            dispatch(_getUserAccounts(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getUserAccountsIndicator(error))
            return { result: "failed", error }
        })
    }
}

// used in distributors
export const getAccounts = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getAccountsIndicator(true))
        return axios.get(API_SERVER + '/api/accounts', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getAccountsIndicator(response))
            dispatch(_getAccounts(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getAccountsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const addAccount = (payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_addAccountIndicator(true))
        return axios.post(API_SERVER + '/api/accounts', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_addAccountIndicator(response))
            dispatch(_addAccount(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_addAccountIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const getAccount = (customer_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getAccountIndicator(true))
        return axios.get(API_SERVER + '/api/accounts/' + customer_id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getAccountIndicator(response))
            dispatch(_getAccount(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getAccountIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const updateAccount = (customer_id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_updateAccountIndicator(true))
        return axios.put(API_SERVER + '/api/accounts/' + customer_id, payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("updateAccount response.data")
            // console.log(response.data)
            dispatch(_updateAccountIndicator(response))
            dispatch(_updateAccount(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_updateAccountIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const checkWhoAmI = () => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_whoAmIIndicator(true))
        return axios.get(API_SERVER + '/api/me', {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_whoAmIIndicator(response))
            dispatch(_whoAmI(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_whoAmIIndicator(error))
            return { result: "failed", error }
        })
    }
}

// export const deleteAccount = (customer_id) => {
//     const cookies = new Cookies()

//     return (dispatch) => {
//         dispatch(_deleteAccountIndicator(true))
//         return axios.delete(API_SERVER + '/api/accounts/' + customer_id, {
//             "headers": {
//                 "Accept": "application/json",
//                 "Authorization": "Bearer " + cookies.get('customer_token')
//             }
//         }).then(response => {
//             dispatch(_deleteAccountIndicator(response))
//             dispatch(_deleteAccount(response.data))
//             return { result: "success", data: response.data }
//         }).catch((error) => {
//             dispatch(_deleteAccountIndicator(error))
//             return { result: "failed", error }
//         })
//     }
// }