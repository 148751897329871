// get all media assets
export const GET_MEDIA_ASSETS = 'GET_MEDIA_ASSETS'
export const GET_MEDIA_ASSETS_INDICATOR = 'GET_MEDIA_ASSETS_INDICATOR'

export const GET_MEDIA_ASSET = 'GET_MEDIA_ASSET'
export const GET_MEDIA_ASSET_INDICATOR = 'GET_MEDIA_ASSET_INDICATOR'

export const ADD_MEDIA_ASSET = 'ADD_MEDIA_ASSET'
export const ADD_MEDIA_ASSET_INDICATOR = 'ADD_MEDIA_ASSET_INDICATOR'

export const UPDATE_MEDIA_ASSET = 'UPDATE_MEDIA_ASSET'
export const UPDATE_MEDIA_ASSET_INDICATOR = 'UPDATE_MEDIA_ASSET_INDICATOR'

export const DELETE_MEDIA_ASSET = 'DELETE_MEDIA_ASSET'
export const DELETE_MEDIA_ASSET_INDICATOR = 'DELETE_MEDIA_ASSET_INDICATOR'

