import axios from "axios"

import { API_SERVER } from "src/globals"
import Cookies from "universal-cookie"

import {
    GET_LAYOUTS,
    GET_LAYOUTS_INDICATOR,
    GET_LAYOUT,
    GET_LAYOUT_INDICATOR,
    ADD_LAYOUT,
    ADD_LAYOUT_INDICATOR,
    UPDATE_LAYOUT,
    UPDATE_LAYOUT_INDICATOR,
    DELETE_LAYOUT,
    DELETE_LAYOUT_INDICATOR,

    LAYOUT_IS_LOADED,
    LAYOUTS_ARE_LOADED,

    GET_DEVICE_LIST,
    GET_DEVICE_LIST_INDICATOR,
    ADDED_DEVICE_LIST,
    ADDED_DEVICE_LIST_INDICATOR,
    DELETE_ADDED_DEVICE_LIST,
    DELETE_ADDED_DEVICE_LIST_INDICATOR,

    GET_DEVICE_GROUPS_LIST,
    GET_DEVICE_GROUPS_LIST_INDICATOR,
    ADDED_DEVICE_GROUPS_LIST,
    ADDED_DEVICE_GROUPS_LIST_INDICATOR,
    DELETE_ADDED_DEVICE_GROUPS_LIST,
    DELETE_ADDED_DEVICE_GROUPS_LIST_INDICATOR,

    GET_PLAYLISTS,
    GET_PLAYLISTS_INDICATOR,

    UPDATE_LAYOUT_CANVAS,
    UPDATE_LAYOUT_CANVAS_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from "../global_methods"

export const _getLayouts = (data) => ({ type: GET_LAYOUTS, payload: data })
export const _layoutsAreLoaded = (data) => ({
    type: LAYOUTS_ARE_LOADED,
    payload: data,
})
export const _getLayoutsIndicator = (value) => ({
    type: GET_LAYOUTS_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _getLayout = (data) => ({ type: GET_LAYOUT, payload: data })
export const _layoutIsLoaded = (data) => ({
    type: LAYOUT_IS_LOADED,
    payload: data,
})
export const _getLayoutIndicator = (value) => ({
    type: GET_LAYOUT_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _addLayout = (data) => ({ type: ADD_LAYOUT, payload: data })
export const _addLayoutIndicator = (value) => ({
    type: ADD_LAYOUT_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _updateLayout = (data) => ({ type: UPDATE_LAYOUT, payload: data })
export const _updateLayoutIndicator = (value) => ({
    type: UPDATE_LAYOUT_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _deleteLayout = (data) => ({ type: DELETE_LAYOUT, payload: data })
export const _deleteLayoutIndicator = (value) => ({
    type: DELETE_LAYOUT_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _getDeviceList = (data) => ({ type: GET_DEVICE_LIST, payload: data })
export const _getDeviceListIndicator = (value) => ({
    type: GET_DEVICE_LIST_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _addedDeviceList = (data) => ({ type: ADDED_DEVICE_LIST, payload: data })
export const _addedDeviceListIndicator = (value) => ({
    type: ADDED_DEVICE_LIST_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _deleteAddedDeviceList = (data) => ({ type: DELETE_ADDED_DEVICE_LIST, payload: data })
export const _deleteAddedDeviceListIndicator = (value) => ({
    type: DELETE_ADDED_DEVICE_LIST_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _getDeviceGroupsList = (data) => ({ type: GET_DEVICE_GROUPS_LIST, payload: data })
export const _getDeviceGroupsListIndicator = (value) => ({
    type: GET_DEVICE_GROUPS_LIST_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _addedDeviceGroupsList = (data) => ({ type: ADDED_DEVICE_GROUPS_LIST, payload: data })
export const _addedDeviceGroupsListIndicator = (value) => ({
    type: ADDED_DEVICE_GROUPS_LIST_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _deleteAddedDeviceGroupsList = (data) => ({ type: DELETE_ADDED_DEVICE_GROUPS_LIST, payload: data })
export const _deleteAddedDeviceGroupsListIndicator = (value) => ({
    type: DELETE_ADDED_DEVICE_GROUPS_LIST_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _getPlaylists = (data) => ({ type: GET_PLAYLISTS, payload: data })
export const _getPlaylistsIndicator = (value) => ({
    type: GET_PLAYLISTS_INDICATOR,
    payload: indicatorFormatter(value),
})

export const _updateLayoutCanvas = (data) => ({ type: UPDATE_LAYOUT_CANVAS, payload: data })
export const _updateLayoutCanvasIndicator = (value) => ({
    type: UPDATE_LAYOUT_CANVAS_INDICATOR,
    payload: indicatorFormatter(value),
})

export const getLayouts = (pagination_props) => {
    const cookies = new Cookies()
    const selected_account = cookies.get("selected_account")

    return (dispatch) => {
        dispatch(_getLayoutsIndicator(true))
        return axios
            .get(
                API_SERVER + "/api/v2/accounts/" + selected_account.id + "/layouts",
                {
                    params: pagination_props,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_getLayoutsIndicator(response))
                dispatch(_getLayouts(response.data))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_getLayoutsIndicator(error))
                return { result: "failed", error }
            })
    }
}

export const addLayout = (payload) => {
    const cookies = new Cookies()
    const selected_account = cookies.get("selected_account")

    return (dispatch) => {
        dispatch(_addLayoutIndicator(true))
        return axios
            .post(
                API_SERVER + "/api/v2/accounts/" + selected_account.id + "/layouts",
                { resource: payload },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_addLayoutIndicator(response))
                dispatch(_addLayout(response.data))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_addLayoutIndicator(error))
                return { result: "failed", error }
            })
    }
}

export const getLayout = (id) => {
    const cookies = new Cookies()
    const selected_account = cookies.get("selected_account")

    return (dispatch) => {
        dispatch(_getLayoutIndicator(true))
        return axios
            .get(
                API_SERVER +
                "/api/v2/accounts/" +
                selected_account.id +
                "/layouts/" +
                id,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_getLayoutIndicator(response))
                dispatch(_getLayout(response.data))
                // dispatch(_layoutIsLoaded(true))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_getLayoutIndicator(error))
                return { result: "failed", error }
            })
    }
}

export const updateLayout = (id, payload) => {
    const cookies = new Cookies()
    const selected_account = cookies.get("selected_account")

    return (dispatch) => {
        dispatch(_updateLayoutIndicator(true))
        return axios
            .put(
                API_SERVER + "/api/v2/accounts/" + selected_account.id + "/layouts/" + id,
                { resource: payload },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_updateLayoutIndicator(response))
                dispatch(_updateLayout(response.data))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_updateLayoutIndicator(error))
                return { result: "failed", error }
            })
    }
}

export const deleteLayout = (id) => {
    const cookies = new Cookies()
    const selected_account = cookies.get("selected_account")

    return (dispatch) => {
        dispatch(_deleteLayoutIndicator(true))
        return axios
            .delete(API_SERVER + "/api/v2/accounts/" + selected_account.id + "/layouts/" + id,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                })
            .then((response) => {
                // console.log(response)
                dispatch(_deleteLayoutIndicator(response))
                dispatch(_deleteLayout(response.data))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_deleteLayoutIndicator(error))
                return { result: "failed", error }
            })
    }
}

// we ca use get devices from accounts_devices
export const getDeviceList = (account_id, id, pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getDeviceListIndicator(true))
        return axios
            .get(API_SERVER + "/api/v2/accounts/" + account_id + "/layouts/" + id + "/devices",
                {
                    params: pagination_props,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                })
            .then((response) => {
                // console.log(response)
                dispatch(_getDeviceListIndicator(response))
                dispatch(_getDeviceList(response.data))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_getDeviceListIndicator(error))
                return { result: "failed", error }
            })
    }
}

// export const addedDeviceList = (account_id, id, device_id) => {
//     const cookies = new Cookies()

//     return (dispatch) => {
//         dispatch(_addedDeviceListIndicator(true))
//         return axios
//             .post(API_SERVER + "/api/v2/accounts/" + account_id + "/layouts/" + id + "/devices/" + device_id + "?_method=PATCH", {},
//                 {
//                     headers: {
//                         Accept: "application/json",
//                         Authorization: "Bearer " + cookies.get("user_token"),
//                     },
//                 })
//             .then((response) => {
//                 // console.log(response)
//                 dispatch(_addedDeviceListIndicator(response))
//                 dispatch(_addedDeviceList(response.data))
//                 return { result: "success", data: response.data }
//             })
//             .catch((error) => {
//                 dispatch(_addedDeviceListIndicator(error))
//                 return { result: "failed", error }
//             })
//     }
// }

// export const deleteAddedDeviceList = (account_id, id, device_id) => {
//     const cookies = new Cookies()

//     return (dispatch) => {
//         dispatch(_deleteAddedDeviceListIndicator(true))
//         return axios
//             .delete(API_SERVER + "/api/v2/accounts/" + account_id + "/layouts/" + id + "/devices/" + device_id,
//                 {
//                     headers: {
//                         Accept: "application/json",
//                         Authorization: "Bearer " + cookies.get("user_token"),
//                     },
//                 })
//             .then((response) => {
//                 // console.log(response)
//                 dispatch(_deleteAddedDeviceListIndicator(response))
//                 dispatch(_deleteAddedDeviceList(response.data))
//                 return { result: "success", data: response.data }
//             })
//             .catch((error) => {
//                 dispatch(_deleteAddedDeviceListIndicator(error))
//                 return { result: "failed", error }
//             })
//     }
// }

// we ca use get devices from accounts_groups
export const getDeviceGroupsList = (account_id, id, pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getDeviceGroupsListIndicator(true))
        return axios
            .get(API_SERVER + "/api/v2/accounts/" + account_id + "/layouts/" + id + "/device_groups",
                {
                    params: pagination_props,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                })
            .then((response) => {
                // console.log(response)
                dispatch(_getDeviceGroupsListIndicator(response))
                dispatch(_getDeviceGroupsList(response.data))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_getDeviceGroupsListIndicator(error))
                return { result: "failed", error }
            })
    }
}

// export const addedDeviceGroupsList = (account_id, id, device_group_id) => {
//     const cookies = new Cookies()

//     // const selected_account = cookies.get("selected_account")

//     return (dispatch) => {
//         dispatch(_addedDeviceGroupsListIndicator(true))
//         return axios
//             .post(API_SERVER + "/api/v2/accounts/" + account_id + "/layouts/" + id + "/device_groups/" + device_group_id + "?_method=PATCH", {},
//                 {
//                     headers: {
//                         Accept: "application/json",
//                         Authorization: "Bearer " + cookies.get("user_token"),
//                     },
//                 })
//             .then((response) => {
//                 // console.log(response)
//                 dispatch(_addedDeviceGroupsListIndicator(response))
//                 dispatch(_addedDeviceGroupsList(response.data))
//                 return { result: "success", data: response.data }
//             })
//             .catch((error) => {
//                 dispatch(_addedDeviceGroupsListIndicator(error))
//                 return { result: "failed", error }
//             })
//     }
// }

// export const deleteAddedDeviceGroupsList = (account_id, id, device_group_id) => {
//     const cookies = new Cookies()

//     return (dispatch) => {
//         dispatch(_deleteAddedDeviceGroupsListIndicator(true))
//         return axios
//             .delete(API_SERVER + "/api/v2/accounts/" + account_id + "/layouts/" + id + "/device_groups/" + device_group_id,
//                 {
//                     headers: {
//                         Accept: "application/json",
//                         Authorization: "Bearer " + cookies.get("user_token"),
//                     },
//                 })
//             .then((response) => {
//                 // console.log(response)
//                 dispatch(_deleteAddedDeviceGroupsListIndicator(response))
//                 dispatch(_deleteAddedDeviceGroupsList(response.data))
//                 return { result: "success", data: response.data }
//             })
//             .catch((error) => {
//                 dispatch(_deleteAddedDeviceGroupsListIndicator(error))
//                 return { result: "failed", error }
//             })
//     }
// }

export const getPlaylists = (pagination_props, account_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getPlaylistsIndicator(true))
        return axios
            .get(
                API_SERVER + "/api/v2/accounts/" + account_id + "/playlists",
                {
                    params: pagination_props,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_getPlaylistsIndicator(response))
                dispatch(_getPlaylists(response.data))
                return { result: "success", data: response.data }
            })
            .catch((error) => {
                dispatch(_getPlaylistsIndicator(error))
                return { result: "failed", error }
            })
    }
}

// export const updateLayoutCanvas = (account_id, id, payload) => {
//     const cookies = new Cookies()

//     return (dispatch) => {
//         dispatch(_updateLayoutCanvasIndicator(true))
//         return axios
//             .post(API_SERVER + "/api/v2/accounts/" + account_id + "/layouts/" + id + "?_method=PATCH", payload,
//                 {
//                     headers: {
//                         Accept: "application/json",
//                         Authorization: "Bearer " + cookies.get("user_token"),
//                     },
//                 }
//             )
//             .then((response) => {
//                 dispatch(_updateLayoutCanvasIndicator(response))
//                 dispatch(_updateLayoutCanvas(response.data))
//                 return { result: "success", data: response.data }
//             })
//             .catch((error) => {
//                 dispatch(_updateLayoutCanvasIndicator(error))
//                 return { result: "failed", error }
//             })
//     }
// }
