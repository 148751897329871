import {
    GET_DISTRIBUTORS,
    GET_DISTRIBUTORS_INDICATOR,
    ADD_DISTRIBUTOR,
    ADD_DISTRIBUTOR_INDICATOR,
    GET_DISTRIBUTOR,
    GET_DISTRIBUTOR_INDICATOR,
    UPDATE_DISTRIBUTOR,
    UPDATE_DISTRIBUTOR_INDICATOR,
    DELETE_DISTRIBUTOR,
    DELETE_DISTRIBUTOR_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    distributors: [],
    getDistributorsIndicator: defaultIndicatorValue,
    addedDistributor: {},
    addDistributorIndicator: defaultIndicatorValue,
    distributor: {},
    getDistributorIndicator: defaultIndicatorValue,
    updatedDistributor: {},
    updateDistributorIndicator: defaultIndicatorValue,
    deletedDistributor: {},
    deleteDistributorIndicator: defaultIndicatorValue,
}

const super_admin_distributors = (state = initialState, action) => {
    switch (action.type) {

        case GET_DISTRIBUTORS:
            return { ...state, distributors: action.payload }

        case GET_DISTRIBUTORS_INDICATOR:
            return { ...state, getDistributorsIndicator: action.payload }

        case ADD_DISTRIBUTOR:
            return { ...state, addedDistributor: action.payload }

        case ADD_DISTRIBUTOR_INDICATOR:
            return { ...state, addedDistributorIndicator: action.payload }

        case GET_DISTRIBUTOR:
            return { ...state, distributor: action.payload }

        case GET_DISTRIBUTOR_INDICATOR:
            return { ...state, getDistributorIndicator: action.payload }

        case UPDATE_DISTRIBUTOR:
            return { ...state, updatedDistributor: action.payload }

        case UPDATE_DISTRIBUTOR_INDICATOR:
            return { ...state, updateDistributorIndicator: action.payload }

        case DELETE_DISTRIBUTOR:
            return { ...state, deletedDistributor: action.payload }

        case DELETE_DISTRIBUTOR_INDICATOR:
            return { ...state, deleteDistributorIndicator: action.payload }

        default:
            return state;
    }
}

export default super_admin_distributors