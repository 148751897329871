import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_INDICATOR,
  UPDATE_PROFILE_SETTINGS,
  UPDATE_PROFILE_SETTINGS_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'
export const _updateProfileSettings = data => ({ type: UPDATE_PROFILE_SETTINGS, payload: data })
export const _updateProfileSettingsIndicator = value => ({ type: UPDATE_PROFILE_SETTINGS_INDICATOR, payload: indicatorFormatter(value) })

export const _updatePassword = data => ({ type: UPDATE_PASSWORD, payload: data })
export const _updatePasswordIndicator = value => ({ type: UPDATE_PASSWORD_INDICATOR, payload: indicatorFormatter(value) })
  
export const updateProfileSettings = (payload) => {
  const cookies = new Cookies()

  return (dispatch) => {
      dispatch(_updateProfileSettingsIndicator(true));
      console.log ("payload", payload)
      return axios.patch(API_SERVER + '/api/profile', payload, {
          headers: {
              "Accept": "application/json",
              "Authorization": "Bearer " + cookies.get('user_token')
          }
      }).then(response => {
          // console.log("updateAccountUser response.data")
          // console.log(response.data)
          dispatch(_updateProfileSettingsIndicator(response))
          dispatch(_updateProfileSettings(response.data))
          return { result: "success", data: response.data }
      }).catch((error) => {
          dispatch(_updateProfileSettingsIndicator(error))
          return { result: "failed", error }
      })
  }
}

export const updatePassword = (payload) => {
  const cookies = new Cookies()

  return (dispatch) => {
      dispatch(_updatePasswordIndicator(true));
      console.log ("payload", payload)
      return axios.patch(API_SERVER + '/api/profile/password', payload, {
          headers: {
              "Accept": "application/json",
              "Authorization": "Bearer " + cookies.get('user_token')
          }
      }).then(response => {
          // console.log("updateAccountUser response.data")
          // console.log(response.data)
          dispatch(_updatePasswordIndicator(response))
          dispatch(_updatePassword(response.data))
          return { result: "success", data: response.data }
      }).catch((error) => {
          dispatch(_updatePasswordIndicator(error))
          return { result: "failed", error }
      })
  }
}
