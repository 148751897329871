import axios from "axios";

import { API_SERVER } from "src/globals";
import Cookies from "universal-cookie";

import {
  GET_MEDIA_ASSETS,
  GET_MEDIA_ASSETS_INDICATOR,
  GET_MEDIA_ASSET,
  GET_MEDIA_ASSET_INDICATOR,
  ADD_MEDIA_ASSET,
  ADD_MEDIA_ASSET_INDICATOR,
  UPDATE_MEDIA_ASSET,
  UPDATE_MEDIA_ASSET_INDICATOR,
  DELETE_MEDIA_ASSET,
  DELETE_MEDIA_ASSET_INDICATOR,
} from "../constants/action-types";

import { indicatorFormatter } from "../global_methods";

export const _getMediaAssets = (data) => ({
  type: GET_MEDIA_ASSETS,
  payload: data,
});
export const _getMediaAssetsIndicator = (value) => ({
  type: GET_MEDIA_ASSETS_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _getMediaAsset = (data) => ({
  type: GET_MEDIA_ASSET,
  payload: data,
});
export const _getMediaAssetIndicator = (value) => ({
  type: GET_MEDIA_ASSET_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _addMediaAsset = (data) => ({
  type: ADD_MEDIA_ASSET,
  payload: data,
});
export const _addMediaAssetIndicator = (value) => ({
  type: ADD_MEDIA_ASSET_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _updateMediaAsset = (data) => ({
  type: UPDATE_MEDIA_ASSET,
  payload: data,
});
export const _updateMediaAssetIndicator = (value) => ({
  type: UPDATE_MEDIA_ASSET_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _deleteMediaAsset = (data) => ({
  type: DELETE_MEDIA_ASSET,
  payload: data,
});
export const _deleteMediaAssetIndicator = (value) => ({
  type: DELETE_MEDIA_ASSET_INDICATOR,
  payload: indicatorFormatter(value),
});

export const getMediaAssets = (pagination_props) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_getMediaAssetsIndicator(true));
    return axios
      .get(
        API_SERVER +
          "/api/v2/accounts/" +
          selected_account.id +
          "/media_assets",
        {
          params: pagination_props,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_getMediaAssetsIndicator(response));
        dispatch(_getMediaAssets(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_getMediaAssetsIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const addMediaAsset = (payload) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_addMediaAssetIndicator(true));
    return axios
      .post(
        API_SERVER + "/api/accounts/" + selected_account.id + "/media_assets",
        payload,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_addMediaAssetIndicator(response));
        dispatch(_addMediaAsset(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_addMediaAssetIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const getMediaAsset = (id) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_getMediaAssetIndicator(true));
    return axios
      .get(
        API_SERVER +
          "/api/v2/accounts/" +
          selected_account.id +
          "/media_assets/" +
          id,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_getMediaAssetIndicator(response));
        dispatch(_getMediaAsset(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_getMediaAssetIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const updateMediaAsset = (id, payload) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_updateMediaAssetIndicator(true));
    return axios
      .post(
        API_SERVER +
          "/api/accounts/" +
          selected_account.id +
          "/media_assets/" +
          id +
          "?_method=PATCH",
        payload,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_updateMediaAssetIndicator(response));
        dispatch(_updateMediaAsset(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        console.log(error);
        dispatch(_updateMediaAssetIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const deleteMediaAsset = (id) => {
  const cookies = new Cookies();
  const selected_account = cookies.get("selected_account");

  return (dispatch) => {
    dispatch(_deleteMediaAssetIndicator(true));
    return axios
      .delete(
        API_SERVER +
          "/api/v2/accounts/" +
          selected_account.id +
          "/media_assets/" +
          id,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_deleteMediaAssetIndicator(response));
        dispatch(_deleteMediaAsset(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_deleteMediaAssetIndicator(error));
        return { result: "failed", error };
      });
  };
};
