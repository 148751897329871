export const GET_LAYOUTS = "GET_LAYOUTS"
export const LAYOUTS_ARE_LOADED = "LAYOUTS_ARE_LOADED"
export const GET_LAYOUTS_INDICATOR = "GET_LAYOUTS_INDICATOR"

export const GET_LAYOUT = "GET_LAYOUT"
export const LAYOUT_IS_LOADED = "LAYOUT_IS_LOADED"
export const GET_LAYOUT_INDICATOR = "GET_LAYOUT_INDICATOR"

export const ADD_LAYOUT = "ADD_LAYOUT"
export const ADD_LAYOUT_INDICATOR = "ADD_LAYOUT_INDICATOR"

export const UPDATE_LAYOUT = "UPDATE_LAYOUT"
export const UPDATE_LAYOUT_INDICATOR = "UPDATE_LAYOUT_INDICATOR"

export const DELETE_LAYOUT = "DELETE_LAYOUT"
export const DELETE_LAYOUT_INDICATOR = "DELETE_LAYOUT_INDICATOR"

export const GET_DEVICE_LIST = 'GET_DEVICE_LIST'
export const GET_DEVICE_LIST_INDICATOR = 'GET_DEVICE_LIST_INDICATOR'

export const ADDED_DEVICE_LIST = 'ADDED_DEVICE_LIST'
export const ADDED_DEVICE_LIST_INDICATOR = 'ADDED_DEVICE_LIST_INDICATOR'

export const DELETE_ADDED_DEVICE_LIST = 'DELETE_ADDED_DEVICE_LIST'
export const DELETE_ADDED_DEVICE_LIST_INDICATOR = 'DELETE_ADDED_DEVICE_LIST_INDICATOR'

export const GET_DEVICE_GROUPS_LIST = 'GET_DEVICE_GROUPS_LIST'
export const GET_DEVICE_GROUPS_LIST_INDICATOR = 'GET_DEVICE_GROUPS_LIST_INDICATOR'

export const ADDED_DEVICE_GROUPS_LIST = 'ADDED_DEVICE_GROUPS_LIST'
export const ADDED_DEVICE_GROUPS_LIST_INDICATOR = 'ADDED_DEVICE_GROUPS_LIST_INDICATOR'

export const DELETE_ADDED_DEVICE_GROUPS_LIST = 'DELETE_ADDED_DEVICE_GROUPS_LIST'
export const DELETE_ADDED_DEVICE_GROUPS_LIST_INDICATOR = 'DELETE_ADDED_DEVICE_GROUPS_LIST_INDICATOR'

export const GET_PLAYLISTS = 'GET_PLAYLISTS'
export const GET_PLAYLISTS_INDICATOR = 'GET_PLAYLISTS_INDICATOR'

export const UPDATE_LAYOUT_CANVAS = 'UPDATE_LAYOUT_CANVAS'
export const UPDATE_LAYOUT_CANVAS_INDICATOR = 'UPDATE_LAYOUT_CANVAS_INDICATOR'