import {
    GET_ACCOUNT_USERS,
    GET_ACCOUNT_USERS_INDICATOR,
    ADD_ACCOUNT_USER,
    ADD_ACCOUNT_USER_INDICATOR,
    GET_ACCOUNT_USER,
    GET_ACCOUNT_USER_INDICATOR,
    UPDATE_ACCOUNT_USER,
    UPDATE_ACCOUNT_USER_INDICATOR,
    DELETE_ACCOUNT_USER,
    DELETE_ACCOUNT_USER_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    account_users: [],
    getAccountUsersIndicator: defaultIndicatorValue,
    addedAccountUser: {},
    addAccountUserIndicator: defaultIndicatorValue,
    account_user: {},
    getAccountUserIndicator: defaultIndicatorValue,
    updatedAccountUser: {},
    updateAccountUserIndicator: defaultIndicatorValue,
    deletedAccountUser: {},
    deleteAccountUserIndicator: defaultIndicatorValue,
}

const accounts_users = (state = initialState, action) => {
    switch (action.type) {

        case GET_ACCOUNT_USERS:
            return { ...state, account_users: action.payload }

        case GET_ACCOUNT_USERS_INDICATOR:
            return { ...state, getAccountUsersIndicator: action.payload }

        case ADD_ACCOUNT_USER:
            return { ...state, addedAccountUser: action.payload }

        case ADD_ACCOUNT_USER_INDICATOR:
            return { ...state, addedAccountUserIndicator: action.payload }

        case GET_ACCOUNT_USER:
            return { ...state, account_user: action.payload }

        case GET_ACCOUNT_USER_INDICATOR:
            return { ...state, getAccountUserIndicator: action.payload }

        case UPDATE_ACCOUNT_USER:
            return { ...state, updatedAccountUser: action.payload }

        case UPDATE_ACCOUNT_USER_INDICATOR:
            return { ...state, updateAccountUserIndicator: action.payload }

        case DELETE_ACCOUNT_USER:
            return { ...state, deletedAccountUser: action.payload }

        case DELETE_ACCOUNT_USER_INDICATOR:
            return { ...state, deleteAccountUserIndicator: action.payload }

        default:
            return state;
    }
}

export default accounts_users