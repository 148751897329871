export const GET_DEVICES = 'GET_DEVICES'
export const GET_DEVICES_INDICATOR = 'GET_DEVICES_INDICATOR'

export const GET_DEVICE = 'GET_DEVICE'
export const GET_DEVICE_INDICATOR = 'GET_DEVICE_INDICATOR'

export const ADD_DEVICE = 'ADD_DEVICE'
export const ADD_DEVICE_INDICATOR = 'ADD_DEVICE_INDICATOR'

export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const UPDATE_DEVICE_INDICATOR = 'UPDATE_DEVICE_INDICATOR'

export const DELETE_DEVICE = 'DELETE_DEVICE'
export const DELETE_DEVICE_INDICATOR = 'DELETE_DEVICE_INDICATOR'
