import {
    GET_USER_ACTIVITY_LOGS,
    GET_USER_ACTIVITY_LOGS_INDICATOR,
    GET_USER_ACTIVITY_LOG,
    GET_USER_ACTIVITY_LOG_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    userActivityLogs: [],
    getUserActivityLogsIndicator: defaultIndicatorValue,
    userActivityLog: {},
    getUserActivityLogIndicator: defaultIndicatorValue,
}

const super_admin_user_activity_logs = (state = initialState, action) => {
    switch (action.type) {

        case GET_USER_ACTIVITY_LOGS:
            return { ...state, userActivityLogs: action.payload }

        case GET_USER_ACTIVITY_LOGS_INDICATOR:
            return { ...state, getUserActivityLogsIndicator: action.payload }

        case GET_USER_ACTIVITY_LOG:
            return { ...state, userActivityLog: action.payload }

        case GET_USER_ACTIVITY_LOG_INDICATOR:
            return { ...state, getUserActivityLogIndicator: action.payload }

        default:
            return state;
    }
}

export default super_admin_user_activity_logs