// get all devices
export const GET_GROUPS = 'GET_GROUPS'
export const GET_GROUPS_INDICATOR = 'GET_GROUPS_INDICATOR'

export const GET_GROUP = 'GET_GROUP'
export const GET_GROUP_INDICATOR = 'GET_GROUP_INDICATOR'

export const ADD_GROUP = 'ADD_GROUP'
export const ADD_GROUP_INDICATOR = 'ADD_GROUP_INDICATOR'

export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_INDICATOR = 'UPDATE_GROUP_INDICATOR'

export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_INDICATOR = 'DELETE_GROUP_INDICATOR'
