import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers/index";
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

const logger = createLogger({collapsed: true});

var middleware = [thunk];

if( process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
} 

const store = createStore( rootReducer, applyMiddleware(...middleware));

export default store;