import axios from 'axios'

import { API_SERVER } from 'src/globals'

import {
    NEEDS_RE_AUTHENTICATION,
    VERIFY_REGISTRATION_TOKEN,
    VERIFY_REGISTRATION_TOKEN_INDICATOR,
    VERIFY_RESET_PASSWORD_TOKEN,
    VERIFY_RESET_PASSWORD_TOKEN_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _setNeedsReAuthentication = data => ({ type: NEEDS_RE_AUTHENTICATION, payload: data })

export const _verifyRegistrationToken = data => ({ type: VERIFY_REGISTRATION_TOKEN, payload: data })
export const _verifyRegistrationTokenIndicator = value => ({ type: VERIFY_REGISTRATION_TOKEN_INDICATOR, payload: indicatorFormatter(value) })

export const _verifyResetPasswordToken = data => ({ type: VERIFY_RESET_PASSWORD_TOKEN, payload: data })
export const _verifyResetPasswordTokenIndicator = value => ({ type: VERIFY_RESET_PASSWORD_TOKEN_INDICATOR, payload: indicatorFormatter(value) })

export const setNeedsReAuthentication = (payload) => {
    return (dispatch) => {
        console.log("auth setNeedsReAuthentication dispatch called")
        dispatch( _setNeedsReAuthentication(payload))
    }
}

export const verifyRegistrationToken = (registration_token) => {
    return (dispatch) => {
        dispatch(_verifyRegistrationTokenIndicator(true));
        return axios.get(API_SERVER + '/api/registration?token=' + registration_token, {
            headers: {
                "Accept": "application/json",
            }
        }).then(response => {
            dispatch(_verifyRegistrationTokenIndicator(response))
            dispatch(_verifyRegistrationToken(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_verifyRegistrationTokenIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const verifyResetPasswordToken = (registration_token) => {
    return (dispatch) => {
        dispatch(_verifyResetPasswordTokenIndicator(true));
        return axios.get(API_SERVER + '/api/password/reset?token=' + registration_token, {
            headers: {
                "Accept": "application/json",
            }
        }).then(response => {
            dispatch(_verifyResetPasswordTokenIndicator(response))
            dispatch(_verifyResetPasswordToken(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_verifyResetPasswordTokenIndicator(error))
            return { result: "failed", error }
        })
    }
}