import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_INDICATOR,
  UPDATE_PROFILE_SETTINGS,
  UPDATE_PROFILE_SETTINGS_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
  profile_settings: {},
  updateProfileSettingsIndicator: defaultIndicatorValue,

  password: {},
  updatePasswordIndicator: defaultIndicatorValue,
}

const account_settings = (state = initialState, action) => {
  
  switch (action.type) {
      case UPDATE_PROFILE_SETTINGS:
        return { ...state, profile_settings: action.payload }

      case UPDATE_PROFILE_SETTINGS_INDICATOR:
        return { ...state, updateProfileSettingsIndicator: action.payload }
        
      case UPDATE_PASSWORD:
        return { ...state, password: action.payload }

      case UPDATE_PASSWORD_INDICATOR:
        return { ...state, updatePasswordIndicator: action.payload }
      
      default:
          return state;
  }
}

export default account_settings;