import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    GET_PHONE_APPS,
    GET_PHONE_APPS_INDICATOR,
    ADD_PHONE_APP,
    ADD_PHONE_APP_INDICATOR,
    GET_PHONE_APP,
    GET_PHONE_APP_INDICATOR,
    DELETE_PHONE_APP,
    DELETE_PHONE_APP_INDICATOR,
    VIEW_PHONE_APP,
    VIEW_PHONE_APP_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'


export const _getPhoneApps = data => ({ type: GET_PHONE_APPS, payload: data })
export const _getPhoneAppsIndicator = value => ({ type: GET_PHONE_APPS_INDICATOR, payload: indicatorFormatter(value) })

export const _addPhoneApp = data => ({ type: ADD_PHONE_APP, payload: data })
export const _addPhoneAppIndicator = value => ({ type: ADD_PHONE_APP_INDICATOR, payload: indicatorFormatter(value) })

export const _getPhoneApp = data => ({ type: GET_PHONE_APP, payload: data })
export const _getPhoneAppIndicator = value => ({ type: GET_PHONE_APP_INDICATOR, payload: indicatorFormatter(value) })

export const _deletePhoneApp = data => ({ type: DELETE_PHONE_APP, payload: data })
export const _deletePhoneAppIndicator = value => ({ type: DELETE_PHONE_APP_INDICATOR, payload: indicatorFormatter(value) })

export const _viewPhoneApp = data => ({ type: VIEW_PHONE_APP, payload: data })
export const _viewPhoneAppIndicator = value => ({ type: VIEW_PHONE_APP_INDICATOR, payload: indicatorFormatter(value) })

export const getPhoneApps = (pagination_props) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_getPhoneAppsIndicator(true));
        return axios.get(API_SERVER + '/api/phone_apps', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getPhoneAppsIndicator(response))
            dispatch(_getPhoneApps(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getPhoneAppsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const addPhoneApp = (payload, onUploadProgress) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_addPhoneAppIndicator(true));
        return axios.post(API_SERVER + '/api/phone_apps', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            },
            onUploadProgress
        }).then(response => {
            dispatch(_addPhoneAppIndicator(response))
            dispatch(_addPhoneApp(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_addPhoneAppIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const getPhoneApp = (id) => {
  const cookies = new Cookies()
  return (dispatch) => {
      dispatch(_getPhoneAppIndicator(true));
      return axios.get(API_SERVER + '/api/phone_apps/' + id, {
          headers: {
              "Accept": "application/json",
              "Authorization": "Bearer " + cookies.get('user_token')
          }
      }).then(response => {
          dispatch(_getPhoneAppIndicator(response))
          dispatch(_getPhoneApp(response.data))
          return { result: "success", data: response.data }
      }).catch((error) => {
          dispatch(_getPhoneAppIndicator(error))
          return { result: "failed", error }
      })
  }
}

export const deletePhoneApp = (id) => {
  const cookies = new Cookies()
  return (dispatch) => {
      dispatch(_deletePhoneAppIndicator(true));
      return axios.delete(API_SERVER + '/api/phone_apps/' + id, {
          headers: {
              "Accept": "application/json",
              "Authorization": "Bearer " + cookies.get('user_token')
          }
      }).then(response => {
          dispatch(_deletePhoneAppIndicator(response))
          dispatch(_deletePhoneApp(response.data))
          return { result: "success", data: response.data }
      }).catch((error) => {
          dispatch(_deletePhoneAppIndicator(error))
          return { result: "failed", error }
      })
  }
}

export const viewPhoneApp = (id) => {
  const cookies = new Cookies()
  return (dispatch) => {
      dispatch(_viewPhoneAppIndicator(true));
      return axios.delete(API_SERVER + '/api/phone_apps/' + id, {
          headers: {
              "Accept": "application/json",
              "Authorization": "Bearer " + cookies.get('user_token')
          }
      }).then(response => {
          dispatch(_viewPhoneAppIndicator(response))
          dispatch(_viewPhoneApp(response.data))
          return { result: "success", data: response.data }
      }).catch((error) => {
          dispatch(_viewPhoneAppIndicator(error))
          return { result: "failed", error }
      })
  }
}