import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    GET_FIRMWARES,
    GET_FIRMWARES_INDICATOR,
    GET_FIRMWARE,
    GET_FIRMWARE_INDICATOR,
    ADD_FIRMWARE,
    ADD_FIRMWARE_INDICATOR,
    UPDATE_FIRMWARE,
    UPDATE_FIRMWARE_INDICATOR,
    DELETE_FIRMWARE,
    DELETE_FIRMWARE_INDICATOR,
    TOGGLE_DISABLE_FIRMWARE,
    TOGGLE_DISABLE_FIRMWARE_INDICATOR
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'


export const _getFirmwares = data => ({ type: GET_FIRMWARES, payload: data })
export const _getFirmwaresIndicator = value => ({ type: GET_FIRMWARES_INDICATOR, payload: indicatorFormatter(value) })

export const _getFirmware = data => ({ type: GET_FIRMWARE, payload: data })
export const _getFirmwareIndicator = value => ({ type: GET_FIRMWARE_INDICATOR, payload: indicatorFormatter(value) })

export const _addFirmware = data => ({ type: ADD_FIRMWARE, payload: data })
export const _addFirmwareIndicator = value => ({ type: ADD_FIRMWARE_INDICATOR, payload: indicatorFormatter(value) })

export const _updateFirmware = data => ({ type: UPDATE_FIRMWARE, payload: data })
export const _updateFirmwareIndicator = value => ({ type: UPDATE_FIRMWARE_INDICATOR, payload: indicatorFormatter(value) })

export const _deleteFirmware = data => ({ type: DELETE_FIRMWARE, payload: data })
export const _deleteFirmwareIndicator = value => ({ type: DELETE_FIRMWARE_INDICATOR, payload: indicatorFormatter(value) })

export const _toggleDisableFirmware = data => ({ type: TOGGLE_DISABLE_FIRMWARE, payload: data })
export const _toggleDisableFirmwareIndicator = value => ({ type: TOGGLE_DISABLE_FIRMWARE_INDICATOR, payload: indicatorFormatter(value) })

export const getFirmwares = (pagination_props) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_getFirmwaresIndicator(true));
        return axios.get(API_SERVER + '/api/firmwares', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getFirmwaresIndicator(response))
            dispatch(_getFirmwares(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getFirmwaresIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const addFirmware = (payload, onUploadProgress) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_addFirmwareIndicator(true));
        return axios.post(API_SERVER + '/api/firmwares', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            },
            onUploadProgress
        }).then(response => {
            dispatch(_addFirmwareIndicator(response))
            dispatch(_addFirmware(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_addFirmwareIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const getFirmware = (id) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_getFirmwareIndicator(true));
        return axios.get(API_SERVER + '/api/firmwares/' + id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getFirmwareIndicator(response))
            dispatch(_getFirmware(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getFirmwareIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const updateFirmware = (id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_updateFirmwareIndicator(true));
        return axios.post(API_SERVER + '/api/firmwares/' + id + '?_method=PATCH', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_updateFirmwareIndicator(response))
            dispatch(_updateFirmware(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            dispatch(_updateFirmwareIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const deleteFirmware = (id) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_deleteFirmwareIndicator(true));
        return axios.delete(API_SERVER + '/api/firmwares/' + id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_deleteFirmwareIndicator(response))
            dispatch(_deleteFirmware(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_deleteFirmwareIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const toggleDisableFirmware = (id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_toggleDisableFirmwareIndicator(true));
        return axios.patch(API_SERVER + '/api/firmwares/' + id + '/toggle_disable', [], {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_toggleDisableFirmwareIndicator(response))
            dispatch(_toggleDisableFirmware(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            dispatch(_toggleDisableFirmwareIndicator(error))
            return { result: "failed", error }
        })
    }
}

