import {
    UTILS_GET_TIMEZONES,
    UTILS_GET_TIMEZONES_INDICATOR,
    UTILS_GET_DEVICES,
    UTILS_GET_DEVICES_INDICATOR,
    UTILS_GET_DEVICE_GROUPS,
    UTILS_GET_DEVICE_GROUPS_INDICATOR,
    UTILS_GET_MEDIA_ASSETS,
    UTILS_GET_MEDIA_ASSETS_INDICATOR,
    UTILS_GET_PLAYLISTS,
    UTILS_GET_PLAYLISTS_INDICATOR,

    UTILS_GET_DISPLAY_SIZES,
    UTILS_GET_DISPLAY_SIZES_INDICATOR,
    UTILS_GET_FONT_SIZES,
    UTILS_GET_FONT_SIZES_INDICATOR,
    UTILS_GET_FONT_STYLES,
    UTILS_GET_FONT_STYLES_INDICATOR,
    UTILS_GET_COLOR_TYPES,
    UTILS_GET_COLOR_TYPES_INDICATOR,

    UTILS_GET_SA_DEVICES,
    UTILS_GET_SA_DEVICES_INDICATOR,
    UTILS_GET_SA_DEVICE_GROUPS,
    UTILS_GET_SA_DEVICE_GROUPS_INDICATOR,

    UTILS_GET_LATEST_PHONEAPP_ANDROID,
    UTILS_GET_LATEST_PHONEAPP_ANDROID_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    timezones: [],
    getTimezonesIndicator: defaultIndicatorValue,
    devices: [],
    getDevicesIndicator: defaultIndicatorValue,
    groups: [],
    getGroupsIndicator: defaultIndicatorValue,
    mediaAssets: [],
    getMediaAssetsIndicator: defaultIndicatorValue,
    playlists: [],
    getPlaylistsIndicator: defaultIndicatorValue,
    displaySizes: [],
    getDisplaySizesIndicator: defaultIndicatorValue,
    fontSizes: [],
    getFontSizesIndicator: defaultIndicatorValue,
    fontStyles: [],
    getFontStylesIndicator: defaultIndicatorValue,
    colorTypes: [],
    getColorTypesIndicator: defaultIndicatorValue,

    saDevices: [],
    getSADevicesIndicator: defaultIndicatorValue,
    saGroups: [],
    getSAGroupsIndicator: defaultIndicatorValue,

    latestPhoneAppAndroid: {},
    getLatestPhoneAppAndroidIndicator: defaultIndicatorValue,
}

const utilities = (state = initialState, action) => {
    switch (action.type) {

        case UTILS_GET_TIMEZONES:
            return { ...state, timezones: action.payload }

        case UTILS_GET_TIMEZONES_INDICATOR:
            return { ...state, getTimezonesIndicator: action.payload }

        case UTILS_GET_DEVICES:
            return { ...state, devices: action.payload }

        case UTILS_GET_DEVICES_INDICATOR:
            return { ...state, getDevicesIndicator: action.payload }

        case UTILS_GET_DEVICE_GROUPS:
            return { ...state, groups: action.payload }

        case UTILS_GET_DEVICE_GROUPS_INDICATOR:
            return { ...state, getGroupsIndicator: action.payload }

        case UTILS_GET_MEDIA_ASSETS:
            return { ...state, mediaAssets: action.payload }

        case UTILS_GET_MEDIA_ASSETS_INDICATOR:
            return { ...state, getMediaAssetsIndicator: action.payload }

        case UTILS_GET_PLAYLISTS:
            return { ...state, playlists: action.payload }

        case UTILS_GET_PLAYLISTS_INDICATOR:
            return { ...state, getPlaylistsIndicator: action.payload }

        case UTILS_GET_DISPLAY_SIZES:
            return { ...state, displaySizes: action.payload }

        case UTILS_GET_DISPLAY_SIZES_INDICATOR:
            return { ...state, getDisplaySizesIndicator: action.payload }

        case UTILS_GET_FONT_SIZES:
            return { ...state, fontSizes: action.payload }

        case UTILS_GET_FONT_SIZES_INDICATOR:
            return { ...state, getFontSizesIndicator: action.payload }

        case UTILS_GET_FONT_STYLES:
            return { ...state, fontStyles: action.payload }

        case UTILS_GET_FONT_STYLES_INDICATOR:
            return { ...state, getFontStylesIndicator: action.payload }

        case UTILS_GET_COLOR_TYPES:
            return { ...state, colorTypes: action.payload }

        case UTILS_GET_COLOR_TYPES_INDICATOR:
            return { ...state, getColorTypesIndicator: action.payload }

        case UTILS_GET_SA_DEVICES:
            return { ...state, saDevices: action.payload }

        case UTILS_GET_SA_DEVICES_INDICATOR:
            return { ...state, getSADevicesIndicator: action.payload }

        case UTILS_GET_SA_DEVICE_GROUPS:
            return { ...state, saGroups: action.payload }

        case UTILS_GET_SA_DEVICE_GROUPS_INDICATOR:
            return { ...state, getSAGroupsIndicator: action.payload }

        case UTILS_GET_LATEST_PHONEAPP_ANDROID:
            return { ...state, latestPhoneAppAndroid: action.payload }

        case UTILS_GET_LATEST_PHONEAPP_ANDROID_INDICATOR:
            return { ...state, getLatestPhoneAppAndroidIndicator: action.payload }

        default:
            return state;
    }
}

export default utilities