import axios from "axios";

import { API_SERVER } from "src/globals";
import Cookies from "universal-cookie";

import {
    GET_GROUPS,
    GET_GROUPS_INDICATOR,
    GET_GROUP,
    GET_GROUP_INDICATOR,
    ADD_GROUP,
    ADD_GROUP_INDICATOR,
    UPDATE_GROUP,
    UPDATE_GROUP_INDICATOR,
    // DELETE_GROUP,
    // DELETE_GROUP_INDICATOR
} from "../constants/action-types";

import { indicatorFormatter } from "../global_methods";

export const _getGroups = (data) => ({ type: GET_GROUPS, payload: data });
export const _getGroupsIndicator = (value) => ({
    type: GET_GROUPS_INDICATOR,
    payload: indicatorFormatter(value),
});

export const _getGroup = (data) => ({ type: GET_GROUP, payload: data });
export const _getGroupIndicator = (value) => ({
    type: GET_GROUP_INDICATOR,
    payload: indicatorFormatter(value),
});

export const _addGroup = (data) => ({ type: ADD_GROUP, payload: data });
export const _addGroupIndicator = (value) => ({
    type: ADD_GROUP_INDICATOR,
    payload: indicatorFormatter(value),
});

export const _updateGroup = (data) => ({ type: UPDATE_GROUP, payload: data });
export const _updateGroupIndicator = (value) => ({
    type: UPDATE_GROUP_INDICATOR,
    payload: indicatorFormatter(value),
});

// export const _deleteGroup = data => ({ type: DELETE_GROUP, payload: data })
// export const _deleteGroupIndicator = value => ({ type: DELETE_GROUP_INDICATOR, payload: indicatorFormatter(value) })

export const getGroups = (pagination_props) => {
    const cookies = new Cookies();
    const selected_account = cookies.get("selected_account");

    return (dispatch) => {
        dispatch(_getGroupsIndicator(true));
        return axios
            .get(
                API_SERVER +
                "/api/v2/accounts/" +
                selected_account.id +
                "/device_groups",
                {
                    params: pagination_props,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_getGroupsIndicator(response));
                dispatch(_getGroups(response.data));
                return { result: "success", data: response.data };
            })
            .catch((error) => {
                dispatch(_getGroupsIndicator(error));
                return { result: "failed", error };
            });
    };
};

export const addGroup = (payload) => {
    const cookies = new Cookies();
    const selected_account = cookies.get("selected_account");

    return (dispatch) => {
        dispatch(_addGroupIndicator(true));
        return axios
            .post(
                API_SERVER + "/api/v2/accounts/" + selected_account.id + "/device_groups",
                { "resource": payload },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_addGroupIndicator(response));
                dispatch(_addGroup(response.data));
                return { result: "success", data: response.data };
            })
            .catch((error) => {
                dispatch(_addGroupIndicator(error));
                return { result: "failed", error };
            });
    };
};

export const getGroup = (id) => {
    const cookies = new Cookies();
    const selected_account = cookies.get("selected_account");

    return (dispatch) => {
        dispatch(_getGroupIndicator(true));
        return axios
            .get(
                API_SERVER +
                "/api/v2/accounts/" +
                selected_account.id +
                "/device_groups/" +
                id,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_getGroupIndicator(response));
                dispatch(_getGroup(response.data));
                return { result: "success", data: response.data };
            })
            .catch((error) => {
                dispatch(_getGroupIndicator(error));
                return { result: "failed", error };
            });
    };
};

export const updateGroup = (id, payload) => {
    const cookies = new Cookies();
    const selected_account = cookies.get("selected_account");

    return (dispatch) => {
        dispatch(_updateGroupIndicator(true));
        return axios
            .post(
                API_SERVER +
                "/api/v2/accounts/" +
                selected_account.id +
                "/device_groups/" +
                id +
                "?_method=PATCH",
                { "resource": payload },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + cookies.get("user_token"),
                    },
                }
            )
            .then((response) => {
                dispatch(_updateGroupIndicator(response));
                dispatch(_updateGroup(response.data));
                return { result: "success", data: response.data };
            })
            .catch((error) => {
                console.log(error);
                dispatch(_updateGroupIndicator(error));
                return { result: "failed", error };
            });
    };
};

// export const getGroupsList = (pagination_props, id) => {
//   const cookies = new Cookies();
//   const selected_account = cookies.get("selected_account");

//   return (dispatch) => {
//     dispatch(_getGroupIndicator(true));
//     return axios
//       .get( API_SERVER + "/api/v2/accounts/" + selected_account.id + "/layouts/" + id + "/device_groups",
//         {
//           params: pagination_props,
//           headers: {
//             Accept: "application/json",
//             Authorization: "Bearer " + cookies.get("user_token"),
//           },
//         }
//       )
//       .then((response) => {
//         dispatch(_getGroupIndicator(response));
//         dispatch(_getGroup(response.data));
//         return { result: "success", data: response.data };
//       })
//       .catch((error) => {
//         dispatch(_getGroupIndicator(error));
//         return { result: "failed", error };
//       });
//   };
// };