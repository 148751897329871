import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    GET_ACCOUNT_USERS,
    GET_ACCOUNT_USERS_INDICATOR,
    ADD_ACCOUNT_USER,
    ADD_ACCOUNT_USER_INDICATOR,
    GET_ACCOUNT_USER,
    GET_ACCOUNT_USER_INDICATOR,
    UPDATE_ACCOUNT_USER,
    UPDATE_ACCOUNT_USER_INDICATOR,
    ACCEPT_INVITATION,
    ACCEPT_INVITATION_INDICATOR,
    DELETE_ACCOUNT_USER,
    DELETE_ACCOUNT_USER_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _getAccountUsers = data => ({ type: GET_ACCOUNT_USERS, payload: data })
export const _getAccountUsersIndicator = value => ({ type: GET_ACCOUNT_USERS_INDICATOR, payload: indicatorFormatter(value) })

export const _addAccountUser = data => ({ type: ADD_ACCOUNT_USER, payload: data })
export const _addAccountUserIndicator = value => ({ type: ADD_ACCOUNT_USER_INDICATOR, payload: indicatorFormatter(value) })

export const _getAccountUser = data => ({ type: GET_ACCOUNT_USER, payload: data })
export const _getAccountUserIndicator = value => ({ type: GET_ACCOUNT_USER_INDICATOR, payload: indicatorFormatter(value) })

export const _updateAccountUser = data => ({ type: UPDATE_ACCOUNT_USER, payload: data })
export const _updateAccountUserIndicator = value => ({ type: UPDATE_ACCOUNT_USER_INDICATOR, payload: indicatorFormatter(value) })

export const _acceptInvitation = data => ({ type: ACCEPT_INVITATION, payload: data })
export const _acceptInvitationIndicator = value => ({ type: ACCEPT_INVITATION_INDICATOR, payload: indicatorFormatter(value) })

export const _deleteAccountUser = data => ({ type: DELETE_ACCOUNT_USER, payload: data })
export const _deleteAccountUserIndicator = value => ({ type: DELETE_ACCOUNT_USER_INDICATOR, payload: indicatorFormatter(value) })

export const getAccountUsers = (account_id, pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getAccountUsersIndicator(true));
        return axios.get(API_SERVER + '/api/accounts/' + account_id + '/users', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log("getAccountUsers success")
            console.log(response)
            dispatch(_getAccountUsersIndicator(response))
            dispatch(_getAccountUsers(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log("getAccountUsers error")
            console.log(error)
            dispatch(_getAccountUsersIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const addAccountUser = (account_id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_addAccountUserIndicator(true));
        return axios.post(API_SERVER + '/api/accounts/' + account_id + '/users', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_addAccountUserIndicator(response))
            dispatch(_addAccountUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_addAccountUserIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const getAccountUser = (account_id, user_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_getAccountUserIndicator(true));
        return axios.get(API_SERVER + '/api/accounts/' + account_id + '/users/' + user_id, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_getAccountUserIndicator(response))
            dispatch(_getAccountUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_getAccountUserIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const updateAccountUser = (account_id, user_id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_updateAccountUserIndicator(true));
        return axios.patch(API_SERVER + '/api/accounts/' + account_id + '/users/' + user_id, payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_updateAccountUserIndicator(response))
            dispatch(_updateAccountUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_updateAccountUserIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const acceptInivitation = (account_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_acceptInvitation(account_id))
        dispatch(_acceptInvitationIndicator(true))
        return axios.post(API_SERVER + '/api/accounts/' + account_id + '/accept_invite', [], {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_acceptInvitationIndicator(response))
            dispatch(_acceptInvitation(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_acceptInvitationIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const deleteAccountUser = (account_id, user_id) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_deleteAccountUserIndicator(true));
        return axios.delete(API_SERVER + '/api/accounts/' + account_id + '/users/' + user_id, {
            "headers": {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_deleteAccountUserIndicator(response))
            dispatch(_deleteAccountUser(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_deleteAccountUserIndicator(error))
            return { result: "failed", error }
        })
    }
}