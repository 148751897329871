export const UTILS_GET_TIMEZONES = 'UTILS_GET_TIMEZONES'
export const UTILS_GET_TIMEZONES_INDICATOR = 'UTILS_GET_TIMEZONES_INDICATOR'

export const UTILS_GET_DEVICES = 'UTILS_GET_DEVICES'
export const UTILS_GET_DEVICES_INDICATOR = 'UTILS_GET_DEVICES_INDICATOR'

export const UTILS_GET_DEVICE_GROUPS = 'UTILS_GET_DEVICE_GROUPS'
export const UTILS_GET_DEVICE_GROUPS_INDICATOR = 'UTILS_GET_DEVICE_GROUPS_INDICATOR'

export const UTILS_GET_MEDIA_ASSETS = 'UTILS_GET_MEDIA_ASSETS'
export const UTILS_GET_MEDIA_ASSETS_INDICATOR = 'UTILS_GET_MEDIA_ASSETS_INDICATOR'

export const UTILS_GET_PLAYLISTS = 'UTILS_GET_PLAYLISTS'
export const UTILS_GET_PLAYLISTS_INDICATOR = 'UTILS_GET_PLAYLISTS_INDICATOR'

export const UTILS_GET_DISPLAY_SIZES = 'UTILS_GET_DISPLAY_SIZES'
export const UTILS_GET_DISPLAY_SIZES_INDICATOR = 'UTILS_GET_DISPLAY_SIZES_INDICATOR'

export const UTILS_GET_FONT_SIZES = 'UTILS_GET_FONT_SIZES'
export const UTILS_GET_FONT_SIZES_INDICATOR = 'UTILS_GET_FONT_SIZES_INDICATOR'

export const UTILS_GET_FONT_STYLES= 'UTILS_GET_FONT_STYLES'
export const UTILS_GET_FONT_STYLES_INDICATOR = 'UTILS_GET_FONT_STYLES_INDICATOR'

export const UTILS_GET_COLOR_TYPES= 'UTILS_GET_COLOR_TYPES'
export const UTILS_GET_COLOR_TYPES_INDICATOR = 'UTILS_GET_COLOR_TYPES_INDICATOR'

export const UTILS_GET_SA_DEVICES = 'UTILS_GET_SA_DEVICES'
export const UTILS_GET_SA_DEVICES_INDICATOR = 'UTILS_GET_SA_DEVICES_INDICATOR'

export const UTILS_GET_SA_DEVICE_GROUPS = 'UTILS_GET_SA_DEVICE_GROUPS'
export const UTILS_GET_SA_DEVICE_GROUPS_INDICATOR = 'UTILS_GET_SA_DEVICE_GROUPS_INDICATOR'

export const UTILS_GET_LATEST_PHONEAPP_ANDROID = 'UTILS_GET_LATEST_PHONEAPP_ANDROID'
export const UTILS_GET_LATEST_PHONEAPP_ANDROID_INDICATOR = 'UTILS_GET_LATEST_PHONEAPP_ANDROID_INDICATOR'