import axios from "axios";

import { API_SERVER } from "src/globals";
import Cookies from "universal-cookie";

import {
  GET_DEVICES,
  GET_DEVICES_INDICATOR,
  GET_DEVICE,
  GET_DEVICE_INDICATOR,
  ADD_DEVICE,
  ADD_DEVICE_INDICATOR,
  UPDATE_DEVICE,
  UPDATE_DEVICE_INDICATOR,
  DELETE_DEVICE,
  DELETE_DEVICE_INDICATOR,
} from "../constants/action-types";

import { indicatorFormatter } from "../global_methods";

export const _getDevices = (data) => ({ type: GET_DEVICES, payload: data });
export const _getDevicesIndicator = (value) => ({
  type: GET_DEVICES_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _getDevice = (data) => ({ type: GET_DEVICE, payload: data });
export const _getDeviceIndicator = (value) => ({
  type: GET_DEVICE_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _addDevice = (data) => ({ type: ADD_DEVICE, payload: data });
export const _addDeviceIndicator = (value) => ({
  type: ADD_DEVICE_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _updateDevice = (data) => ({ type: UPDATE_DEVICE, payload: data });
export const _updateDeviceIndicator = (value) => ({
  type: UPDATE_DEVICE_INDICATOR,
  payload: indicatorFormatter(value),
});

export const _deleteDevice = (data) => ({ type: DELETE_DEVICE, payload: data });
export const _deleteDeviceIndicator = (value) => ({
  type: DELETE_DEVICE_INDICATOR,
  payload: indicatorFormatter(value),
});

export const getDevices = (account_id, pagination_props) => {
  const cookies = new Cookies();

  return (dispatch) => {
    dispatch(_getDevicesIndicator(true));
    return axios
      .get(API_SERVER + "/api/v2/accounts/" + account_id + "/devices", {
        params: pagination_props,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + cookies.get("user_token"),
        },
      })
      .then((response) => {
        // console.log("getDevices response");
        // console.log(response);
        dispatch(_getDevicesIndicator(response));
        dispatch(_getDevices(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_getDevicesIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const addDevice = (account_id, payload) => {
  const cookies = new Cookies();

  return (dispatch) => {
    dispatch(_addDeviceIndicator(true));
    return axios
      .post(
        API_SERVER + "/api/v2/accounts/" + account_id + "/devices",
        { resource: payload },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_addDeviceIndicator(response));
        dispatch(_addDevice(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_addDeviceIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const getDevice = (account_id, id) => {
  const cookies = new Cookies();

  return (dispatch) => {
    dispatch(_getDeviceIndicator(true));
    return axios
      .get(API_SERVER + "/api/v2/accounts/" + account_id + "/devices/" + id, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + cookies.get("user_token"),
        },
      })
      .then((response) => {
        console.log(response);
        dispatch(_getDeviceIndicator(response));
        dispatch(_getDevice(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_getDeviceIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const updateDevice = (account_id, id, payload) => {
  const cookies = new Cookies();

  return (dispatch) => {
    dispatch(_updateDeviceIndicator(true));
    return axios
      .post(
        API_SERVER +
          "/api/accounts/" +
          account_id +
          "/devices/" +
          id +
          "?_method=PATCH",
        payload,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(_updateDeviceIndicator(response));
        dispatch(_updateDevice(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        console.log(error);
        dispatch(_updateDeviceIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const nudgeDevicePlaylist = (account_id, id, payload) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_updateDeviceIndicator(true));
        return axios.post(API_SERVER + '/api/accounts/' + account_id + '/devices/' + id + '/playlist_nudge', payload, {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            console.log(response)
            dispatch(_updateDeviceIndicator(response))
            dispatch(_updateDevice(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            console.log(error)
            dispatch(_updateDeviceIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const unregisterDevice = (account_id, id) => {
  const cookies = new Cookies();

  return (dispatch) => {
    dispatch(_deleteDeviceIndicator(true));
    return axios
      .delete(
        API_SERVER + "/api/v2/accounts/" + account_id + "/devices/" + id,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        dispatch(_deleteDeviceIndicator(response));
        dispatch(_deleteDevice(response.data));
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        dispatch(_deleteDeviceIndicator(error));
        return { result: "failed", error };
      });
  };
};

export const rebootDevice = (account_id, id) => {
  const cookies = new Cookies();

  return (dispatch) => {
    return axios
      .post(
        API_SERVER +
          "/api/v2/accounts/" +
          account_id +
          "/devices/" +
          id +
          "/reboot",
        [],
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + cookies.get("user_token"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        return { result: "success", data: response.data };
      })
      .catch((error) => {
        console.log(error);
        return { result: "failed", error };
      });
  };
};