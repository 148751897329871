import { CSpinner, CContainer } from "@coreui/react";
import moment from "moment";
import momentTz from "moment-timezone";

import $ from "jquery";
import "animate.css";
import Cookies from "universal-cookie";

import gifshot from "gifshot";

export const APP_NAME = "Digital Barricade";
export const API_SERVER = process.env.REACT_APP_API_SERVER;
export const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;

// export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY

export const DISABLED_TWILIO_PHONE_NUMBER_VERIFICATION_SMS = true;
export const DISABLED_SMTP_VERIFICATION_EMAIL = true;

// export const INLINE_LOADING = ({contClassName, msg, msgClassName, align}) => {
//     return(
//         <div className={contClassName}>
//             <CSpinner color="primary" className="d-block" />
//             <p className={msgClassName}>{msg}</p>
//         </div>
//     )
// }

export const LOADING = (
    <div className="h-100 p-5 d-flex justify-content-center align-items-center">
        <CSpinner color="primary" className="d-block" />
    </div>
);

export const SUSPENSE_FALLBACK_ELEMENT = (
    <CContainer fluid className="h-100">
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div>
                {LOADING}
                <h3>Please wait...</h3>
            </div>
        </div>
    </CContainer>
);

// used for radio/dropdown
export const GENDERS = [
    { value: "male", display_name: "Male" },
    { value: "female", display_name: "Female" },
];

export const SCHOOL_JOBS = [
    { value: "student", display_name: "Student" },
    { value: "faculty_member", display_name: "Faculty Member" },
];

export const RACES = [
    { value: "", display_name: "" },
    { value: "AIAN", display_name: "American Indian Or Alaskan Native" },
    { value: "AS", display_name: "Asian" },
    { value: "BAA", display_name: "Black Or African American" },
    { value: "HAPI", display_name: "Native Hawaiian Or Other Pacific Islander" },
    { value: "MR", display_name: "More Than One" },
    { value: "OPI", display_name: "Other Pacific Islander" },
    { value: "OT", display_name: "Other" },
    { value: "UK", display_name: "Unknown/Unreported" },
    { value: "WH", display_name: "White" },
    { value: "BAA", display_name: "Afro-American" },
    { value: "AIAN", display_name: "Native American" },
    { value: "UK", display_name: "Unknown" },
];

export const ETHNICITIES = [
    { value: "", display_name: "" },
    { value: "hispanic", display_name: "Hispanic" },
    { value: "not_hispanic", display_name: "Not Hispanic" },
    { value: "unknown", display_name: "Unknown" },
];

export const PERSONAL_IDS = [
    { value: "drivers_license", display_name: "I have a driver license" },
    {
        value: "state_issued_non_driver",
        display_name: "I have a state-issued non-driver ID",
    },
    { value: "neither", display_name: "I have neither" },
];

export const YES_NO = [
    { value: "yes", display_name: "Yes" },
    { value: "no", display_name: "No" },
];

export const GUARANTOR_RELATIONSHIPS = [
    { value: "self", display_name: "Self" },
    { value: "other", display_name: "Other" },
];

export const UTILS_PAGE_SIZE = 100;

export const default_calendar_interval = {
    fri: false,
    mon: false,
    sat: false,
    sun: false,
    thu: false,
    tue: false,
    wed: false,
    calendar_interval_times: [
        {
            end_time: "",
            start_time: "",
        },
    ],
};

export const DEVICE_TYPES = ["Flexible Display 3 Panel"];

export const DEFAULT_DEVICE_TAGS = [
    "advertisement",
    "broadcast",
    "bulletin",
    "commercial",
    "endorsement",
    "entertainment",
    "exhibit",
    "headline",
    "information",
    "literature",
    "news",
    "notice",
    "poster",
    "presentation",
    "promotion",
    "seasonal",
    "sign",
    "warning",
];

export const FORM_SUBMIT_ERROR_MESSAGE =
    "Must address all required fields and errors in the form before submission.";

export const SUPPORTED_LANGUAGES = [
    { value: "EN", label: "English" },
    { value: "FR", label: "Frence" },
    { value: "SP", label: "Spanish" },
    { value: "JP", label: "Japanese" },
    { value: "KR", label: "Korean" },
];

export const LANGUAGE_TRANSLATION_MAPPING = [
    { value: "en", lang_code: "en" },
    { value: "fr", lang_code: "fr" },
    { value: "sp", lang_code: "es" },
    { value: "jp", lang_code: "ja" },
    { value: "kr", lang_code: "ko" },
];

export const TARGET_DEVICES_SELECTION = [
    { value: "by_group", display_name: "By Group Selection" },
    { value: "by_selected_devices", display_name: "By Device Selection" },
];

export const DEFAULT_BACKGROUND_TYPE = [
    { value: "color", display_name: "Color" },
    { value: "media", display_name: "Media" },
];

export const SCHEDULING_METHODS = [
    { value: "duration", display_name: "Duration Based" },
    { value: "schedule", display_name: "Schedule/Time Based" },
];

export const COLOR_CODES = {
    BLACK: "#000000",
    WHITE: "#FFFFFF",
    RED: "#E91E63",
    BLUE: "#5BB3FF",
    GREEN: "#6BDA71",
    YELLOW: "#FFE961",
    TRANSPARENT: "",
};

export const FONT_SIZE_IN_PX = {
    SMALL: "16px", // 1 rem
    MEDIUM: "24px", //
    LARGE: "32px", // 2 rem
};

export const DAYS_OF_WEEK = [
    { value: "M", display_name: "Monday" },
    { value: "T", display_name: "Tuesday" },
    { value: "W", display_name: "Wednesday" },
    { value: "R", display_name: "Thursday" },
    { value: "F", display_name: "Friday" },
    { value: "S", display_name: "Saturday" },
    { value: "U", display_name: "Sunday" },
];

export const DURATION_UNITS = [
    { value: "seconds", label: "Seconds" },
    // { value: "minutes", label: "Minutes" },
    // { value: "hours", label: "Hours" },
];

// getConstantSpecificKeyValue(YES_NO, "value", "yes", "display_name")
export const getConstantDisplayName = (const_var, val) => {
    var return_val = "";
    const_var.forEach((el) => {
        if (el.value === val) {
            return_val = el.display_name;
        }
    });

    return return_val;
};

export const getFormFieldUsingName = (arr, value) => {
    for (var i = 0, iLen = arr.length; i < iLen; i++) {
        if (arr[i].name === value) return arr[i];
    }
};

export const UtcToTzWithAbbr = ({ row, timezone }) => {
    var utc = momentTz(row.appointment_date).utcOffset(0, true);
    return utc.clone().tz(timezone).format("MM-DD-YYYY hh:mm A z");
};

export const humanReadableDate = (datetime) => {
    return moment(datetime).format("MM-DD-YYYY hh:mm A z");
}

export const getCurrentYear = () => {
    return moment().year();
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
};

// https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
export const dataURItoBlob = (dataurl) => {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?)/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

export const DEFAULT_THUMBNAIL_WIDTH = 230;
export const DEFAULT_THUMBNAIL_HEIGHT = 230;

export const localReadImageFileAsDataURL = (file) => {
    var reader = new FileReader();
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    return new Promise((resolve, reject) => {
        reader.onload = function (event) {
            var img = new Image();
            img.onload = function () {
                var scaleRatio =
                    Math.min(...[DEFAULT_THUMBNAIL_WIDTH, DEFAULT_THUMBNAIL_HEIGHT]) /
                    Math.max(img.width, img.height);
                let w = img.width * scaleRatio;
                let h = img.height * scaleRatio;
                canvas.width = w;
                canvas.height = h;
                ctx.drawImage(img, 0, 0, w, h);

                return resolve({
                    file_object: file,
                    data_url: canvas.toDataURL(file.type),
                });
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    });
};

export const localReadVideoFileAsDataURL = (file) => {
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
        // read video metadata first
        // get width and height
        const video = document.createElement("video");
        video.addEventListener("loadedmetadata", (event) => {
            // then create thumbnail
            reader.onload = () => {
                const videoWidth = video.videoWidth;
                const videoHeight = video.videoHeight;

                var scaleRatio =
                    Math.min(...[DEFAULT_THUMBNAIL_WIDTH, DEFAULT_THUMBNAIL_HEIGHT]) /
                    Math.max(videoWidth, videoHeight);
                let thumbnailWidth = videoWidth * scaleRatio;
                let thumbnailHeight = videoHeight * scaleRatio;

                gifshot.createGIF(
                    {
                        video: [reader.result],
                        gifWidth: thumbnailWidth,
                        gifHeight: thumbnailHeight,
                        numFrames: 10,
                        interval: 0.1,
                        frameDuration: 1,
                    },
                    (obj) => {
                        if (!obj.error) {
                            return resolve({
                                file_object: file,
                                data_url: obj.image,
                            });
                        } else {
                            return resolve({
                                file_object: file,
                                data_url: null,
                            });
                        }
                    }
                );
            };
            reader.readAsDataURL(file);
        });
        video.addEventListener('error', event => {
            return reject(event)
        })
        video.addEventListener('error', event => {
            return reject(event)
        })
        const objectURL = URL.createObjectURL(file)
        video.src = objectURL
    })
}

export const nudgeElement = (el, loop, isSlow) => {
    const element = $(el);
    element.addClass("animate__animated");
    element.addClass("animate__headShake");
    if (loop) {
        element.addClass("animate__infinite");
        element.addClass("animate__duration-2");
    } else {
        element.addClass("animate__repeat-1");
    }

    if (isSlow) {
        element.addClass("animate__slow");
    }

    if (!loop) {
        setTimeout(function () {
            element.removeClass("animate__animated");
            element.removeClass("animate__headShake");
            element.removeClass("animate__repeat-1");
            // element.removeClass('animate__delay-2s')
        }, 1000);
    }
};

export const pluck = (arr, key) => arr.map((i) => i[key]);

export const formDataToJSONString = (formData) => { };
function isObject(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Object;
}

export const syntaxHighlight = (json, tab) => {
    try {
        if (isObject(json)) {
            // console.log("json hasOwnProperty name")
            json = JSON.stringify(json, undefined, tab);
        } else {
            // console.log("json has no name")
            json = JSON.stringify(JSON.parse(json), undefined, tab);
        }
    } catch (e) {
        // console.log(e)
        return "";
    }

    return json;
    // json = json.replace(/&/g, '&amp').replace(/</g, '&lt').replace(/>/g, '&gt')
    // return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
    //     var cls = 'number'
    //     if (/^"/.test(match)) {
    //         if (/:$/.test(match)) {
    //             cls = 'key'
    //         } else {
    //             cls = 'string'
    //         }
    //     } else if (/true|false/.test(match)) {
    //         cls = 'boolean'
    //     } else if (/null/.test(match)) {
    //         cls = 'null'
    //     }
    //     return '<span className="' + cls + '">' + match + '</span>'
    // })
};
export const isParsableJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        //Error
        //JSON is not okay
        return false;
    }

    return true;
};

export const toCamelCase = (str) => {
    if (!str) {
        return "";
    }
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
};

export const maskEmail = (email) => {
    if (!email) {
        return "";
    }
    let str = email;
    str = str.split("");
    let finalArr = [];
    let len = str.indexOf("@");
    str.forEach((item, pos) => {
        pos >= 3 && pos <= len - 4 ? finalArr.push("*") : finalArr.push(str[pos]);
    });
    return finalArr.join("");
};

export const setAuthUser = (data) => {
    console.log("login data")
    console.log(data)
    const cookies = new Cookies();
    cookies.set("user_token", data.token, { path: "/", maxAge: 86400 }); // , 1day = 86400secs
    cookies.set("user_id", data.user.id, { path: "/", maxAge: 86400 });
    cookies.set("user_name", data.user.name, { path: "/", maxAge: 86400 });
    cookies.set("user_email", data.user.email, { path: "/", maxAge: 86400 });
    cookies.set("user_role", data.user.role, { path: "/", maxAge: 86400 });

    const current_account = data.user.current_account;
    if (current_account) {
        cookies.set("account_role", current_account.role, {
            path: "/",
            maxAge: 86400,
        });
    } else {
        cookies.remove("account_role");
    }

    cookies.set(
        "selected_account",
        current_account && current_account.status === "active"
            ? JSON.stringify(current_account)
            : null,
        {
            path: "/",
            maxAge: 86400,
        }
    );
};

export const otherUnitToSeconds = (duration, unit) => {
    var computed_duration = Math.ceil(duration);
    if (unit.toLowerCase() === "minutes") {
        computed_duration = duration * 60;
    } else if (unit.toLowerCase() === "hours") {
        computed_duration = duration * 3600;
    }

    return computed_duration;
};

export const converDurationToUnit = (durationInSeconds, unit) => {
    var computed_duration = Math.ceil(durationInSeconds);
    if (unit.toLowerCase() === "minutes") {
        computed_duration = durationInSeconds / 60;
    } else if (unit.toLowerCase() === "hours") {
        computed_duration = durationInSeconds / 3600;
    }

    return computed_duration;
};

export const durationToHumanReadable = (durationInSeconds, unit) => {
    var computed_duration = Math.ceil(durationInSeconds);
    if (unit.toLowerCase() === "minutes") {
        computed_duration = durationInSeconds / 60;
    } else if (unit.toLowerCase() === "hours") {
        computed_duration = durationInSeconds / 3600;
    }

    return computed_duration + " " + toCamelCase(unit);
};

export const scheduleToHumanReadable = (schedule) => {
    const days = schedule.days
        ? schedule.days.map((initial) => {
            const found = DAYS_OF_WEEK.find((day) => day.value === initial);
            return found ? found.display_name : "";
        })
        : "";

    return (
        days +
        " from " +
        moment(schedule.start_time, "HH:mm").format("hh:mmA") +
        " to " +
        moment(schedule.end_time, "HH:mm").format("hh:mmA")
    );
};

export const calculateIfScheduleHasOverlap = (items) => {
    var expandedSchedule = [];

    items.forEach((item) => {
        if (item.schedule && item.schedule.days) {
            item.schedule.days.forEach((dayInitial) => {
                const dayOfWeek = DAYS_OF_WEEK.find(
                    (dayOfWeek) => dayOfWeek.value === dayInitial
                );

                expandedSchedule.push({
                    state_id: item.state_id,
                    day: dayOfWeek.display_name,
                    start_time: item.schedule.start_time,
                    end_time: item.schedule.end_time,
                });
            });
        }
    });

    return scheduleIdsOverlap(expandedSchedule);
};

function scheduleIdsOverlap(schedules) {
    var ids = [];
    for (let i = 0; i < schedules.length; i++) {
        for (let j = i + 1; j < schedules.length; j++) {
            const scheduleA = schedules[i];
            const scheduleB = schedules[j];

            if (scheduleA.day === scheduleB.day) {
                const startA = moment(scheduleA.start_time, "HH:mm");
                const endA = moment(scheduleA.end_time, "HH:mm");
                const startB = moment(scheduleB.start_time, "HH:mm");
                const endB = moment(scheduleB.end_time, "HH:mm");

                // console.log(startA.format('HH:mm') + " startA isBefore endB " + endB.format('HH:mm') + " && " + endA.format('HH:mm') + " endA isAfter startB " + startB.format('HH:mm'))

                if (startA.isBefore(endB) && endA.isAfter(startB)) {
                    ids.push(scheduleA.state_id);
                    ids.push(scheduleB.state_id);
                }
            }
        }
    }

    return [...new Set(ids)];
}

export const LAYOUT_DIMENSION_PRESETS = [
    { value: "1920x1080", label: "1920 x 1080" },
    { value: "1640x924", label: "1640 x 924" },
    { value: "1080x1080", label: "1080 x 1080" },
    { value: "940x788", label: "940 x 788" },
    { value: "360x28", label: "360 x 28 (Digital Barricade)" },
    { value: "custom", label: "Custom..." },
];

export const LAYOUT_TYPE_OPTIONS = [
    { label: "Normal", value: "normal" },
    // { label: "Grouped", value: "grouped" },
    { label: "Areas", value: "areas" },
    // { label: "Split", value: "split" },
];

export const LAYOUT_TYPES = [
    "normal",
    // "grouped",
    "areas",
    // "split"
];

export const swapAndReorderIndex = (arr, srcIndex, destIndex) => {
    var myArray = [...arr];
    var temp = myArray[srcIndex];
    myArray.splice(srcIndex, 1);
    myArray.splice(destIndex, 0, temp);

    myArray = myArray.map((item, index) => {
        item.z_index = index;
        return item;
    });

    return myArray;
};

export const chunkArraytoN = (arr, n) => {
    var container = Array.from(Array(n), () => []);
    // console.log("container")
    // console.log(container)

    var ctr = 0;
    for (let index = 0; index < arr.length; index++) {
        const element = arr[index];

        if (ctr > n - 1) {
            ctr = 0;
        }

        container[ctr].push(element);
        ctr++;
    }

    return container;
    // const size = Math.ceil(arr.length / n);
    // return Array.from({ length: n }, (v, i) =>
    //     arr.slice(i * size, i * size + size)
    // )
};
