// get all devices
export const SUPER_ADMIN_GET_DEVICES = 'SUPER_ADMIN_GET_DEVICES'
export const SUPER_ADMIN_GET_DEVICES_INDICATOR = 'SUPER_ADMIN_GET_DEVICES_INDICATOR'

export const SUPER_ADMIN_GET_DEVICE = 'SUPER_ADMIN_GET_DEVICE'
export const SUPER_ADMIN_GET_DEVICE_INDICATOR = 'SUPER_ADMIN_GET_DEVICE_INDICATOR'

export const SUPER_ADMIN_UPDATED_DEVICE = 'SUPER_ADMIN_UPDATED_DEVICE'
export const SUPER_ADMIN_UPDATE_DEVICE_INDICATOR = 'SUPER_ADMIN_UPDATE_DEVICE_INDICATOR'

export const SUPER_ADMIN_GET_DEVICE_LOGS = 'SUPER_ADMIN_GET_DEVICE_LOGS'
export const SUPER_ADMIN_GET_DEVICE_LOGS_INDICATOR = 'SUPER_ADMIN_GET_DEVICE_LOGS_INDICATOR'
