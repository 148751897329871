import {
    GET_PLAYLISTS,
    GET_PLAYLISTS_INDICATOR,
    GET_PLAYLIST,
    GET_PLAYLIST_INDICATOR,
    ADD_PLAYLIST,
    ADD_PLAYLIST_INDICATOR,
    UPDATE_PLAYLIST,
    UPDATE_PLAYLIST_INDICATOR,
    DELETE_PLAYLIST,
    DELETE_PLAYLIST_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    playlists: [],
    getPlaylistsIndicator: defaultIndicatorValue,
    playlist: {},
    getPlaylistIndicator: defaultIndicatorValue,
    addedPlaylist: {},
    getAddPlaylistIndicator: defaultIndicatorValue,
    updatedPlaylist: {},
    getUpdatePlaylistIndicator: defaultIndicatorValue,
    deletedPlaylist: {},
    getDeletePlaylistIndicator: defaultIndicatorValue,
}

const accounts_playlists = (state = initialState, action) => {
    switch (action.type) {

        case GET_PLAYLISTS:
            return { ...state, playlists: action.payload }

        case GET_PLAYLISTS_INDICATOR:
            return { ...state, getPlaylistsIndicator: action.payload }

        case GET_PLAYLIST:
            return { ...state, playlist: action.payload }

        case GET_PLAYLIST_INDICATOR:
            return { ...state, getPlaylistIndicator: action.payload }

        case ADD_PLAYLIST:
            return { ...state, addedPlaylist: action.payload }

        case ADD_PLAYLIST_INDICATOR:
            return { ...state, getAddPlaylistIndicator: action.payload }

        case UPDATE_PLAYLIST:
            return { ...state, updatedPlaylist: action.payload }

        case UPDATE_PLAYLIST_INDICATOR:
            return { ...state, getUpdatePlaylistIndicator: action.payload }

        case DELETE_PLAYLIST:
            return { ...state, deletedPlaylist: action.payload }

        case DELETE_PLAYLIST_INDICATOR:
            return { ...state, getDeletePlaylistIndicator: action.payload }

        default:
            return state;
    }
}

export default accounts_playlists