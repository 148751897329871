import {
    GET_USER_ACCOUNTS,
    GET_USER_ACCOUNTS_INDICATOR,
    GET_ACCOUNTS,
    GET_ACCOUNTS_INDICATOR,
    ADD_ACCOUNT,
    ADD_ACCOUNT_INDICATOR,
    GET_ACCOUNT,
    GET_ACCOUNT_INDICATOR,
    UPDATE_ACCOUNT,
    UPDATE_ACCOUNT_INDICATOR,
    ACCEPT_INVITATION,
    ACCEPT_INVITATION_INDICATOR,
    // DELETE_ACCOUNT,
    // DELETE_ACCOUNT_INDICATOR,
    WHO_AM_I,
    WHO_AM_I_INDICATOR,
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    userAccounts: [],
    getUserAccountsIndicator: defaultIndicatorValue,
    accounts: [],
    getAccountsIndicator: defaultIndicatorValue,
    addedAccount: {},
    addAccountIndicator: defaultIndicatorValue,
    account: {},
    getAccountIndicator: defaultIndicatorValue,
    updatedAccount: {},
    updateAccountIndicator: defaultIndicatorValue,
    acceptedInvitation: null,
    acceptInvitationIndicator: defaultIndicatorValue,
    // deletedAccount: {},
    // deleteAccountIndicator: defaultIndicatorValue,
    whoAmI: null,
    whoAmIIndicator: defaultIndicatorValue,
}

const accounts = (state = initialState, action) => {
    switch (action.type) {

        case GET_USER_ACCOUNTS:
            return { ...state, userAccounts: action.payload }

        case GET_USER_ACCOUNTS_INDICATOR:
            return { ...state, getUserAccountsIndicator: action.payload }

        case GET_ACCOUNTS:
            return { ...state, accounts: action.payload }

        case GET_ACCOUNTS_INDICATOR:
            return { ...state, getAccountsIndicator: action.payload }

        case ADD_ACCOUNT:
            return { ...state, addedAccount: action.payload }

        case ADD_ACCOUNT_INDICATOR:
            return { ...state, addedAccountIndicator: action.payload }

        case GET_ACCOUNT:
            return { ...state, account: action.payload }

        case GET_ACCOUNT_INDICATOR:
            return { ...state, getAccountIndicator: action.payload }

        case UPDATE_ACCOUNT:
            return { ...state, updatedAccount: action.payload }

        case UPDATE_ACCOUNT_INDICATOR:
            return { ...state, updateAccountIndicator: action.payload }

        case ACCEPT_INVITATION:
            return { ...state, acceptedInvitation: action.payload }

        case ACCEPT_INVITATION_INDICATOR:
            return { ...state, acceptInvitationIndicator: action.payload }

        case WHO_AM_I:
            return { ...state, whoAmI: action.payload }

        case WHO_AM_I_INDICATOR:
            return { ...state, whoAmIIndicator: action.payload }

        // case DELETE_ACCOUNT:
        //     return { ...state, deletedAccount: action.payload }

        // case DELETE_ACCOUNT_INDICATOR:
        //     return { ...state, deleteAccountIndicator: action.payload }

        default:
            return state;
    }
}

export default accounts