import {
    NEEDS_RE_AUTHENTICATION,
    VERIFY_REGISTRATION_TOKEN,
    VERIFY_REGISTRATION_TOKEN_INDICATOR,
    VERIFY_RESET_PASSWORD_TOKEN,
    VERIFY_RESET_PASSWORD_TOKEN_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    needsReAuthentication: false,
    verifiedRegistrationToken: null,
    verifyRegistrationTokenIndicator: defaultIndicatorValue,
    verifiedResetPasswordToken: null,
    verifiedResetPasswordTokenIndicator: defaultIndicatorValue,
}

const auth = (state = initialState, action) => {
    switch (action.type) {

        case NEEDS_RE_AUTHENTICATION:
            return { ...state, needsReAuthentication: action.payload }

        case VERIFY_REGISTRATION_TOKEN:
            return { ...state, verifiedRegistrationToken: action.payload }

        case VERIFY_REGISTRATION_TOKEN_INDICATOR:
            return { ...state, verifyRegistrationTokenIndicator: action.payload }

        case VERIFY_RESET_PASSWORD_TOKEN:
            return { ...state, verifiedResetPasswordToken: action.payload }

        case VERIFY_RESET_PASSWORD_TOKEN_INDICATOR:
            return { ...state, verifiedResetPasswordTokenIndicator: action.payload }

        default:
            return state;
    }
}

export default auth