import axios from 'axios'
import querystring from 'querystring'

export const notify = (notif_payload) => {
    return (dispatch) => {
        dispatch({ type: 'NOTIFY', payload: notif_payload })
    }
}

// msg:ready
// inputLang:en
// outputLang:ja
export const localTranslate = (payload) => {
    // 'https://cors-anywhere.herokuapp.com/http://ec2-13-59-3-200.us-east-2.compute.amazonaws.com:8080/translate?' + querystring.stringify(payload),

    return axios.post(
        'https://proxy.cors.sh/http://ec2-13-59-3-200.us-east-2.compute.amazonaws.com:8080/translate?' + querystring.stringify(payload),
        null,
        {
            headers: {
                // 'x-cors-api-key': 'temp_feeb8dae7c1594184a300eff04db197a',
                'x-cors-api-key': 'temp_b44b7f963ec7212a55696176c1726b1b',
            }
        }
    ).then(response => {
        console.log(response)
        return { result: "success", data: response.data }
    }).catch((error) => {
        console.log(error)
        return { result: "failed", error }
    })

    // let config = {
    //     method: 'post',
    //     maxBodyLength: Infinity,
    //     url: 'http://ec2-13-59-3-200.us-east-2.compute.amazonaws.com:8080/translate',
    //     headers: {
    //         'Access-Control-Allow-Origin': '*',
    //     },
    //     data: "msg=ready&inputLang=en&outputLang=ja"
    // }

    // return axios.request(config)
    //     .then((response) => {
    //         console.log(JSON.stringify(response.data))
    //     })
    //     .catch((error) => {
    //         console.log(error)
    //     })
}