import axios from 'axios'

import { API_SERVER } from 'src/globals'
import Cookies from 'universal-cookie'

import {
    UTILS_GET_TIMEZONES,
    UTILS_GET_TIMEZONES_INDICATOR,
    UTILS_GET_DEVICES,
    UTILS_GET_DEVICES_INDICATOR,
    UTILS_GET_DEVICE_GROUPS,
    UTILS_GET_DEVICE_GROUPS_INDICATOR,
    UTILS_GET_MEDIA_ASSETS,
    UTILS_GET_MEDIA_ASSETS_INDICATOR,

    UTILS_GET_PLAYLISTS,
    UTILS_GET_PLAYLISTS_INDICATOR,
    UTILS_GET_DISPLAY_SIZES,
    UTILS_GET_DISPLAY_SIZES_INDICATOR,

    UTILS_GET_FONT_SIZES,
    UTILS_GET_FONT_SIZES_INDICATOR,
    UTILS_GET_FONT_STYLES,
    UTILS_GET_FONT_STYLES_INDICATOR,
    UTILS_GET_COLOR_TYPES,
    UTILS_GET_COLOR_TYPES_INDICATOR,

    UTILS_GET_SA_DEVICES,
    UTILS_GET_SA_DEVICES_INDICATOR,
    UTILS_GET_SA_DEVICE_GROUPS,
    UTILS_GET_SA_DEVICE_GROUPS_INDICATOR,

    UTILS_GET_LATEST_PHONEAPP_ANDROID,
    UTILS_GET_LATEST_PHONEAPP_ANDROID_INDICATOR,
} from "../constants/action-types"

import { indicatorFormatter } from '../global_methods'

export const _utilsGetTimeZones = data => ({ type: UTILS_GET_TIMEZONES, payload: data })
export const _utilsGetTimeZonesIndicator = value => ({ type: UTILS_GET_TIMEZONES_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetDevices = data => ({ type: UTILS_GET_DEVICES, payload: data })
export const _utilsGetDevicesIndicator = value => ({ type: UTILS_GET_DEVICES_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetGroups = data => ({ type: UTILS_GET_DEVICE_GROUPS, payload: data })
export const _utilsGetGroupsIndicator = value => ({ type: UTILS_GET_DEVICE_GROUPS_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetMediaAssets = data => ({ type: UTILS_GET_MEDIA_ASSETS, payload: data })
export const _utilsGetMediaAssetsIndicator = value => ({ type: UTILS_GET_MEDIA_ASSETS_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetPlaylists = data => ({ type: UTILS_GET_PLAYLISTS, payload: data })
export const _utilsGetPlaylistsIndicator = value => ({ type: UTILS_GET_PLAYLISTS_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetDisplaySizes = data => ({ type: UTILS_GET_DISPLAY_SIZES, payload: data })
export const _utilsGetDisplaySizesIndicator = value => ({ type: UTILS_GET_DISPLAY_SIZES_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetFontSizes = data => ({ type: UTILS_GET_FONT_SIZES, payload: data })
export const _utilsGetFontSizesIndicator = value => ({ type: UTILS_GET_FONT_SIZES_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetFontStyles = data => ({ type: UTILS_GET_FONT_STYLES, payload: data })
export const _utilsGetFontStylesIndicator = value => ({ type: UTILS_GET_FONT_STYLES_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetColorTypes = data => ({ type: UTILS_GET_COLOR_TYPES, payload: data })
export const _utilsGetColorTypesIndicator = value => ({ type: UTILS_GET_COLOR_TYPES_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetSADevices = data => ({ type: UTILS_GET_SA_DEVICES, payload: data })
export const _utilsGetSADevicesIndicator = value => ({ type: UTILS_GET_SA_DEVICES_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsGetSAGroups = data => ({ type: UTILS_GET_SA_DEVICE_GROUPS, payload: data })
export const _utilsGetSAGroupsIndicator = value => ({ type: UTILS_GET_SA_DEVICE_GROUPS_INDICATOR, payload: indicatorFormatter(value) })

export const _utilsLatestPhoneAppAndroid = data => ({ type: UTILS_GET_LATEST_PHONEAPP_ANDROID, payload: data })
export const _utilsLatestPhoneAppAndroidIndicator = value => ({ type: UTILS_GET_LATEST_PHONEAPP_ANDROID_INDICATOR, payload: indicatorFormatter(value) })
// export const getTimeZones = () => {
//     return (dispatch) => {
//         dispatch(_utilsGetTimeZonesIndicator(true));
//         return axios.get(API_SERVER + '/api/utilities/timezones', {
//             headers: {}
//         }).then(response => {
//             dispatch(_utilsGetTimeZonesIndicator(response))
//             dispatch(_utilsGetTimeZones(response.data.collection))
//             return { result: "success", data: response.data }
//         }).catch((error) => {
//             dispatch(_utilsGetTimeZonesIndicator(error))
//             return { result: "failed", error }
//         })
//     }
// }

export const utilsGetDevices = (pagination_props) => {
    const cookies = new Cookies()
    const selected_account = cookies.get('selected_account')

    return (dispatch) => {
        dispatch(_utilsGetDevicesIndicator(true));
        return axios.get(API_SERVER + '/api/accounts/' + selected_account.id + '/utilities/devices', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetDevicesIndicator(response))
            dispatch(_utilsGetDevices(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetDevicesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetGroups = (pagination_props) => {
    const cookies = new Cookies()
    const selected_account = cookies.get('selected_account')

    return (dispatch) => {
        dispatch(_utilsGetGroupsIndicator(true));
        return axios.get(API_SERVER + '/api/accounts/' + selected_account.id + '/utilities/device_groups', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetGroupsIndicator(response))
            dispatch(_utilsGetGroups(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetGroupsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetMediaAssets = (pagination_props) => {
    const cookies = new Cookies()
    const selected_account = cookies.get('selected_account')

    return (dispatch) => {
        dispatch(_utilsGetMediaAssetsIndicator(true));
        return axios.get(API_SERVER + '/api/accounts/' + selected_account.id + '/utilities/media_assets', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetMediaAssetsIndicator(response))
            dispatch(_utilsGetMediaAssets(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetMediaAssetsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetTimezones = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_utilsGetTimeZonesIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/timezones', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetTimeZonesIndicator(response))
            dispatch(_utilsGetTimeZones(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetTimeZonesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetPlaylists = (pagination_props) => {
    const cookies = new Cookies()
    const selected_account = cookies.get('selected_account')

    return (dispatch) => {
        dispatch(_utilsGetPlaylistsIndicator(true));
        return axios.get(API_SERVER + '/api/accounts/' + selected_account.id + '/utilities/playlists', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetPlaylistsIndicator(response))
            dispatch(_utilsGetPlaylists(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetPlaylistsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetDisplaySizes = (pagination_props) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_utilsGetDisplaySizesIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/display_sizes', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetDisplaySizesIndicator(response))
            dispatch(_utilsGetDisplaySizes(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetDisplaySizesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetFontSizes = (pagination_props) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_utilsGetFontSizesIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/font_sizes', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetFontSizesIndicator(response))
            dispatch(_utilsGetFontSizes(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetFontSizesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetFontStyles = (pagination_props) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_utilsGetFontStylesIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/font_styles', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetFontStylesIndicator(response))
            dispatch(_utilsGetFontStyles(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetFontStylesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetColorTypes = (pagination_props) => {
    const cookies = new Cookies()
    return (dispatch) => {
        dispatch(_utilsGetColorTypesIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/color_types', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            // console.log("utilsGetColorTypes response.data")
            // console.log(response.data)

            let data = {
                collection: response.data.collection.filter(color => color.name !== "NONE")
            }

            dispatch(_utilsGetColorTypesIndicator(response))
            dispatch(_utilsGetColorTypes(data))
            return { result: "success", data: data }
        }).catch((error) => {
            dispatch(_utilsGetColorTypesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetSADevices = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_utilsGetSADevicesIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/devices', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetSADevicesIndicator(response))
            dispatch(_utilsGetSADevices(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetSADevicesIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetSAGroups = (pagination_props) => {
    const cookies = new Cookies()

    return (dispatch) => {
        dispatch(_utilsGetSAGroupsIndicator(true));
        return axios.get(API_SERVER + '/api/utilities/device_groups', {
            params: pagination_props,
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + cookies.get('user_token')
            }
        }).then(response => {
            dispatch(_utilsGetSAGroupsIndicator(response))
            dispatch(_utilsGetSAGroups(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsGetSAGroupsIndicator(error))
            return { result: "failed", error }
        })
    }
}

export const utilsGetLastestPhoneAppAndroid = (pagination_props) => {
    return (dispatch) => {
        dispatch(_utilsLatestPhoneAppAndroidIndicator(true));
        return axios.get(API_SERVER + '/download/phoneapp/android').then(response => {
            dispatch(_utilsLatestPhoneAppAndroidIndicator(response))
            dispatch(_utilsLatestPhoneAppAndroid(response.data))
            return { result: "success", data: response.data }
        }).catch((error) => {
            dispatch(_utilsLatestPhoneAppAndroidIndicator(error))
            return { result: "failed", error }
        })
    }
}