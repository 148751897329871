import Cookies from 'universal-cookie'
const cookies = new Cookies()

const booleanSidebarShow = cookies.get('sidebar_show') !== undefined && cookies.get('sidebar_show') !== "responsive" ? JSON.parse(cookies.get('sidebar_show')) : true
const booleanSidebarMinimize = cookies.get('sidebar_minimize') !== undefined ? JSON.parse(cookies.get('sidebar_minimize')) : false

var initialState = {
    toasts: [],
    // sidebarShow: "responsive",
    sidebarShow: booleanSidebarShow === true ? "responsive" : false,
    sidebarMinimize: booleanSidebarMinimize === true ? true : false,
    // systemVersion: cookies.get('SYSTEM_VERSION') !== undefined && ["v1", "v2"].includes(cookies.get('SYSTEM_VERSION')) ? cookies.get('SYSTEM_VERSION') : "v1",
    // systemVersionModalVisible: false,
    // shiftVersion1ModalVisible: false,
    // shiftVersion2ModalVisible: false,
    documentationLinksModalVisible: false,
}

const globals = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SHOW_SIDEBAR":
            return { ...state, sidebarShow: action.sidebarShow }

        case "SET_MINIMIZE_SIDEBAR":
            return { ...state, sidebarMinimize: action.sidebarMinimize }

        // case "SET_SYSTEM_VERSION":
        //     return { ...state, systemVersion: action.systemVersion }

        // case "SET_SYSTEM_VERSION_MODAL_VISIBLE":
        //     return { ...state, systemVersionModalVisible: action.visible }

        // case "SET_SHIFT_VERSION_1_MODAL_VISIBLE":
        //     return { ...state, shiftVersion1ModalVisible: action.visible }

        // case "SET_SHIFT_VERSION_2_MODAL_VISIBLE":
        //     return { ...state, shiftVersion2ModalVisible: action.visible }

        case "SET_DOCUMENTATION_LINKS_MODAL_VISIBLE":
            return { ...state, documentationLinksModalVisible: action.visible }

        case "NOTIFY":
            // console.log(state.toasts)
            return { ...state, toasts: [...state.toasts, action.payload] }

        default:
            return state;
    }
}

export default globals