import {
    GET_GROUPS,
    GET_GROUPS_INDICATOR,
    GET_GROUP,
    GET_GROUP_INDICATOR,
    ADD_GROUP,
    ADD_GROUP_INDICATOR,
    UPDATE_GROUP,
    UPDATE_GROUP_INDICATOR,
    DELETE_GROUP,
    DELETE_GROUP_INDICATOR
} from "../constants/action-types"

var defaultIndicatorValue = { active: false, code: 0, data: [] }

var initialState = {
    groups: [],
    getGroupsIndicator: defaultIndicatorValue,
    group: {},
    getGroupIndicator: defaultIndicatorValue,
    addedGroup: {},
    getAddGroupIndicator: defaultIndicatorValue,
    updatedGroup: {},
    getUpdateGroupIndicator: defaultIndicatorValue,
    deletedGroup: {},
    getDeleteGroupIndicator: defaultIndicatorValue,
}

const accounts_groups = (state = initialState, action) => {
    switch (action.type) {

        case GET_GROUPS:
            return { ...state, groups: action.payload }

        case GET_GROUPS_INDICATOR:
            return { ...state, getGroupsIndicator: action.payload }

        case GET_GROUP:
            return { ...state, group: action.payload }

        case GET_GROUP_INDICATOR:
            return { ...state, getGroupIndicator: action.payload }

        case ADD_GROUP:
            return { ...state, addedGroup: action.payload }

        case ADD_GROUP_INDICATOR:
            return { ...state, getAddGroupIndicator: action.payload }

        case UPDATE_GROUP:
            return { ...state, updatedGroup: action.payload }

        case UPDATE_GROUP_INDICATOR:
            return { ...state, getUpdateGroupIndicator: action.payload }

        case DELETE_GROUP:
            return { ...state, deletedGroup: action.payload }

        case DELETE_GROUP_INDICATOR:
            return { ...state, getDeleteGroupIndicator: action.payload }

        default:
            return state;
    }
}

export default accounts_groups